/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { CountdownPlain } from '../core/CountdownPlain';
import { Stage } from '../core/Stage';
import { useCompletedAtTimer } from '../hooks/useCountdownTimer';
import { useGameState } from '../hooks/useGameState';
import { useSkid } from '../hooks/useSkid';
import { useSkidResultTable } from '../hooks/useSkidResultTable';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidResultLossWin } from './SkidResultLossWin';
import { SkidResultTeamTable } from './SkidResultTeamTable';

export const COOLDOWN_SECONDS = 120 * 1000
export const Cooldown = ({ completedAt, hideCountdown, onCooldownEnd}: { completedAt: Date, hideCountdown?: boolean, onCooldownEnd: () => void}) => {
    const { timeLeft, hasFinished } = useCompletedAtTimer(completedAt, COOLDOWN_SECONDS, true, !hideCountdown)
    useEffect(() => {
        if (hasFinished) {
            onCooldownEnd()
        }
    }, [hasFinished, onCooldownEnd])
    
    const { selectedTeam, isInitialLoading, teams, game } = useSkid();
    const { state } = useGameState(game)
    let { isInitialLoading: isResultInitialLoading, data, tableData} = useSkidResultTable(state?.skid?.id)
    const otherTeamId = teams.find((t) => t.id !== selectedTeam?.id)?.id
    
    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    return (
        <Stage
            isLoading={isInitialLoading || isResultInitialLoading}
            gradiendDegree={0} gradientColorStart={gt[0]} gradientColorEnd={gt[1]}
            top={hideCountdown ? null : <div css={css`
                            font-weight: normal;
                            font-size: 17px;
                            color: white;
                            font-weight:bold;
                            text-align: center;
                        `}
                    >
                        ... es geht weiter in: <CountdownPlain timeLeft={timeLeft} hasFinished={hasFinished} />
            </div>}
        >
        <SkidResultLossWin ownTeamResult={data.resultByTeam[selectedTeam?.id ?? ''] ?? 0} otherTeamResult={data.resultByTeam[otherTeamId ?? ''] ?? 0} skidId={state?.skid?.id}/>
        <SkidScoreBoard left={data.resultByTeam[selectedTeam?.id ?? '']} right={data.resultByTeam[otherTeamId ?? '']} leftLabel='Euer &Oslash; Score' rightLabel='&Oslash; Score Gegner'/>
        {selectedTeam?.id && <SkidResultTeamTable tableData={tableData[selectedTeam!.id]!} teamName={teams.find((t) => t.id === selectedTeam!.id)?.name ?? ''}  />}
    </Stage>);
}