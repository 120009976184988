/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { WAIT_AFTER_COMPLETE } from '../decisions/HotMinute';
import { Skid } from '../hooks/useEvent';
import { useGameState } from '../hooks/useGameState';
import { useSkid } from '../hooks/useSkid';
import { RefereeSkidControlItem } from './RefereeSkidControltem';

export const RefereeSkidControl: React.FunctionComponent<{
}> = () => {
  const { game, teams } = useSkid();
  const { state } = useGameState(game);
  const { isComplete: isGameComplete, skid: activeSkid, stage: activeStage, completedAt, decisionSelections } = state ?? {}
  const hasState = Boolean(state)
  const isRoundOver = completedAt ? ((completedAt as Date).getTime() + WAIT_AFTER_COMPLETE) < (new Date()).getTime() : false

  // TODo: respect non selectable decisions
  const rowState = useMemo(() => {
    const skids = (game?.skids ?? []).sort((a, b) => a.order_number > b.order_number ? 1 : -1);
    const activeSkidIndex = skids.findIndex((skid) => skid.id === activeSkid?.id) ?? undefined

    const skidRows: {
      skid: Skid,
      index: number,
      isActive: boolean,
      isComplete: boolean,
      stage: string,
      completeRateTeams?: number,
      completedAt?: Date
    }[] = [];

    for (let i = 0; i < skids.length; i++) {
      // 1. no game state - start with first round
      if (!hasState) {
        skidRows.push({
          skid: skids[i],
          index: i,
          isActive: i === 0,
          stage: i === 0 ? (skids[i].can_teams_select ? 'enable-decision-selection' : 'team-selection-finished' ): 'waiting',
          isComplete: false
        })
        continue;
      }
    
      if (isGameComplete) {
        skidRows.push({
          skid: skids[i],
          index: i,
          isActive: false,
          isComplete: true,
          stage: 'completed',
        })
        continue;
      }

      // 2. skid in active game state and round is team: update teams decisions
      //    - allow team decision overwrite
      // 3. all teams have decided -> enable user button
      let isComplete = activeSkidIndex > i;
      let isActive = activeSkidIndex === i;
      let stage = isComplete ? 'completed' : (isActive ? 'team-selection-in-progress' : 'waiting')
      let completeRateTeams;

      if (activeStage === 'team') {
        const countTeamSelected = (Object.values(decisionSelections ?? {})).reduce((count: number, decision: any) => {
          return count + (decision[(activeSkid as Skid).id] ? 1 : 0)
        }, 0)
  
        if (countTeamSelected === teams.length) {
          stage = 'team-selection-finished'
        }

        completeRateTeams = countTeamSelected / teams.length
      }

      // 4. user is active: show countdown
      // 5. user has finished: show view result button
      if (activeStage === 'user' && (activeSkid as Skid).id === skids[i].id) {
        isComplete = isRoundOver
        isActive = !isComplete
        stage = !isComplete ? 'user-skidding-in-progress' : 'completed'
      }

      // check previous skid is still active
      if (activeStage === 'user' && skidRows[i - 1] && (activeSkid as Skid).id === skidRows[i - 1].skid.id) {
        isActive = skidRows[i - 1].isComplete
        stage = skids[i].can_teams_select ? 'enable-decision-selection' : 'team-selection-finished';
      }

      skidRows.push({
        skid: skids[i],
        index: i,
        isActive,
        isComplete,
        stage,
        completeRateTeams,
        completedAt
      })
    }
    
    return skidRows;
  }, [game.skids, activeSkid, hasState, isGameComplete, activeStage, isRoundOver, completedAt, decisionSelections, teams.length])
  
  return (
    <>
      <table css={css`width: 100%`}>
          <thead css={css`color: white; font-weight: bold; opacity: 0.6`}>
              <tr>
                  <th css={css`padding: 4px; border-bottom: 1px solid rgba(255,255,255,0.6); width: 20%`}>Round</th>
                  <th css={css`padding: 4px; border-bottom: 1px solid rgba(255,255,255,0.6); width: 40%`}>Team</th>
                  <th css={css`padding: 4px; border-bottom: 1px solid rgba(255,255,255,0.6);  width: 40%`}>User</th>
              </tr>
          </thead>
          <tbody>
          {rowState.length === 0 && <tr><td colSpan={3}>Keine Entscheidungsrunden fürs Spiel konfiguriert</td></tr>}
          {rowState.length > 0 && rowState.map((skid, index) => {
              return <RefereeSkidControlItem
                key={skid.skid.id}
                gameId={game!.id}
                skid={skid.skid}
                index={index}
                stage={skid.stage}
                isActive={skid.isActive}
                isComplete={skid.isComplete}
                completeRateTeams={skid.completeRateTeams}
                completedAt={skid.completedAt}
              />
          })}
          </tbody>
      </table>
    </>
  );
}