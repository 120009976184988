/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';
import { useSkidDecisions } from '../hooks/useSkidDecision';
import { useSkidResultTable } from '../hooks/useSkidResultTable';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidResultTeamTable } from './SkidResultTeamTable';

export const RefereeResultScreen: React.FunctionComponent<{
  skidId?: string,
  cta: string
}> = ({ skidId, cta }) => {

  const { teams, decisionCopy } = useSkid();
  let { data, tableData} = useSkidResultTable(skidId)
  const { data: decisionData } = useSkidDecisions()
  const left = data.resultByTeam[teams[0].id]
  const right = data.resultByTeam[teams[1].id]
  const decisionType = decisionData[skidId ?? '']?.type 
  const decisionName = decisionCopy[decisionType ?? '']?.name ?? decisionType
  const [isOpen, setOpen] = useState<boolean>(false)
  const gt = GradientTypes['default']
  return (
    <div css={css`
        
        color: white;
        font-size: 16px
    `}>
      <div css={css`color: rgba(255,255,255, 0.9); text-decoration: underline; display: inline`} onClick={() => setOpen(true)}>
        {cta}
      </div>
      {isOpen && (
        <div css={css`
        position: fixed;
        border: 6px solid rgba(255,255,255, 0.5);
        top:0;
        margin-top: 5vh;
        left: 30px;
        right: 30px;
        border-radius: 10px;
        z-index: 100000;
        background: ${gt[0]};
          background: linear-gradient(110deg, ${gt[0]} -30%, ${gt[1]} 130%);
        padding: 20px;
        height: 90vh;
        overflow-y: scroll
      `}>
        <div css={css`font-weight: normal; font-size: 18px; opacity: 0.8; margin-bottom: 10px`}>
            {decisionType === 'coin-toss' ? (<>Es wurder um den Coin Toss gespielt</>) : (
                <>Der Gewinner erspielte <strong>"<u>{decisionName ?? 'no-selection'}</u>"</strong></>
            )}
        </div>
        <SkidScoreBoard
          leftLabel={`${teams[0].name} Ø Score`}
          rightLabel={`${teams[1].name} Ø Score`}
          left={left}
          right={right}
        />
        {teams.map(({id}) => <SkidResultTeamTable tableData={tableData[id]!} teamName={teams.find((t) => t.id === id)?.name ?? ''}  />)}
        <span
              onClick={() => setOpen(false)}
              css={css`color: black; background: rgba(255,255,255, 0.5); padding: 5px; position: absolute; top: 0; right: 0px; text-decoration: underline`}>
              schließen
            </span>
      </div>)}
    </div>
  )
}