/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { Stage } from '../core/Stage';
import { DecisionDescription } from '../decisions/DecisionDescription';
import { useSkid } from '../hooks/useSkid';

export const StepCompleted = ({ onClick, headline, description, ctaCopy, showActionCards }: {onClick: () => void, headline: string, description: string, ctaCopy: string, showActionCards?: boolean }) => {
    const { selectedTeam } = useSkid();
    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    return (
        <Stage gradiendDegree={-90} gradientColorStart={gt[0]} gradientColorEnd={gt[1]} bottom={<Button copy={ctaCopy} onClick={onClick} gradiendDegree={189}/>} noTop>
            <div css={css`
                text-align: center;
                color: white;
                padding-bottom: 20px;
                font-weight: bold;
                font-size: 20px;
            `}>
                <div>
                    <svg
                        css={css`
                            fill: white;
                            width: 40px;
                            height: 40px;
                            margin-bottom: 10px;
                            opacity: 0.75;
                        `}
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="48"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>
                    <div>
                        <div css={css`margin-bottom: 10px`}>{headline}</div>
                        <span
                            css={css`
                                font-weight: normal;
                                font-size: 17px;
                            `}
                        >
                            {description}
                        </span>
                        {showActionCards && <div css={css`margin-top: 50px; font-weight: normal;font-size: 17px; text-decoration: underline`}>
                            <DecisionDescription cta='Was für Action-Cards gibt es?' />
                        </div>}
                    </div>
                </div>
            </div>
        </Stage>
    )       
}