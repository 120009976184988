/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Stage } from "../core/Stage";
import { useGameState } from "../hooks/useGameState";
import { useSkid } from '../hooks/useSkid';
import { RefereeSkidControl } from "../referee/RefereeSkidControl";

export const SchiriBoard = () => {
    const { game, isInitialLoading: isSkidInitialLoading } = useSkid();
    const { isInitialLoading: isGameStateInitialLoading } = useGameState(game);
    return (
        <Stage isLoading={isSkidInitialLoading || isGameStateInitialLoading} noTop alignTop>
            <small css={css`color: white; opacity: 0.6`}>Game</small>
            <h2 css={css`margin-bottom: 20px; margin-top: 5px; color: white`}>{game?.name}</h2>
            <RefereeSkidControl />
        </Stage>
    );
}