/** @jsxImportSource @emotion/react */
import { useSkid } from '../hooks/useSkid';
import { useSkidResultTable } from '../hooks/useSkidResultTable';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidResultLossWin } from './SkidResultLossWin';
import { SkidResultTeamTable } from './SkidResultTeamTable';

export const TeamSelectionResultScreen: React.FunctionComponent<{
  skidId?: string,
  teamId: string
}> = ({ skidId, teamId }) => {
  const { teams } = useSkid();
  let { data, tableData} = useSkidResultTable(skidId)
  const otherTeamId = teams.find((t) => t.id !== teamId)?.id
  
  return (
    <>
      <SkidResultLossWin ownTeamResult={data.resultByTeam[teamId] ?? 0} otherTeamResult={data.resultByTeam[otherTeamId ?? ''] ?? 0} skidId={skidId}/>
      <SkidScoreBoard left={data.resultByTeam[teamId ?? '']} right={data.resultByTeam[otherTeamId ?? '']} leftLabel='Euer &Oslash; Score' rightLabel='&Oslash; Score Gegner'/>
      <SkidResultTeamTable tableData={tableData[teamId]!} teamName={teams.find((t) => t.id === teamId)?.name ?? ''}  />
    </>
  );
}