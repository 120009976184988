/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Decision, GameState, saveTeamDecisionState } from "../client/skid-worker";
import { GradientTypes } from "../context/SkidContext";
import { CountdownPlain } from "../core/CountdownPlain";
import { Stage } from "../core/Stage";
import { DecisionSelector } from "../decisions/DecisionSelector";
import { DecisionsContent } from "../decisions/DecisionsContent";
import { WAIT_AFTER_COMPLETE } from "../decisions/HotMinute";
import { TeamSelectionResultScreen } from "../decisions/TeamSelectionResultScreen";
import { useCompletedAtTimer } from "../hooks/useCountdownTimer";
import { useGameState } from "../hooks/useGameState";
import { useSkid } from '../hooks/useSkid';
import { useSkidDecisions } from "../hooks/useSkidDecision";

export const TeamDecisionSelections = () => {
    const params = useParams()
    const teamId = params.teamId as string
    const [decisionId, setDecisionId] = useState<string>();
    const { game, isInitialLoading: isSkidInitialLoading, teams, decisions, decisionCopy } = useSkid();
    const { isInitialLoading: isGameStateInitialLoading, state, refetch } = useGameState(game);
    const { isComplete: isGameComplete, skid: activeSkid, stage: activeStage, completedAt, decisionSelections } = state ?? {}
    const hasState = Boolean(state)
    const isCurrentRoundOver = completedAt ? ((completedAt as Date).getTime() + WAIT_AFTER_COMPLETE) < (new Date()).getTime() : false;

    const queryClient = useQueryClient()
    const { mutateAsync: saveTeamDecision, isLoading: isSavingTeamDecision } = useMutation({
        mutationFn: (data: {gameId: string, skidId: string, decisionId: string, teamId: string}) => {
            return saveTeamDecisionState(data.gameId, data.skidId, data.decisionId, teamId)
        },
        onSuccess: ({ game_id, team_id, skid_id, decision_id }) => {
            const state = queryClient.getQueryData(['gameState', game_id]) as GameState
            const decisionsNew = !state?.decisionSelections ? {} : {...state.decisionSelections}
            if (!decisionsNew[team_id]) {
                decisionsNew[team_id] = {}
            }
            decisionsNew[team_id][skid_id] = decision_id;
            queryClient.setQueryData(['gameState', game_id], {...(state ?? {}), decisionSelections: decisionsNew})
          },
        onError: () => {
            alert('Error saving state')
        }
    })

    const countTeamSelections = Object.keys(decisionSelections?.[teamId] ?? {}).length
    const teamState = useMemo(() => {
        // 1. no game state - start with first round
        if (!hasState) {
            return {
                stage: 'not-started'
            }
        }
    
        if (isGameComplete) {
            return {
                stage: 'completed'
            }
        }
        
    // 2. skid in active game state and round is team: update teams decisions
      //    - allow team decision overwrite
      // 3. all teams have decided -> enable user button
      let stage = 'selection'
      let selected;
      let isComplete = isCurrentRoundOver
      
      if (activeStage === 'team') {
        if (activeSkid && decisionSelections?.[teamId] && decisionSelections?.[teamId][activeSkid.id]) {
            stage = 'selected'
            selected = decisionSelections?.[teamId][activeSkid.id];
        }
      }

      // 4. user is active: show countdown
      // 5. user has finished: show view result button
      if (activeStage === 'user') {
        stage = !isComplete ? 'user-skidding-in-progress' : 'round-done'
      }

        return {
            stage,
            selected,
        }
    }, [hasState, isGameComplete, activeStage, activeSkid, decisionSelections, teamId, isCurrentRoundOver, countTeamSelections])
    
    

    const saveTD = useCallback(() => {
        if (game && activeSkid && decisionId) {
            saveTeamDecision({ gameId: game.id, skidId: activeSkid.id, decisionId, teamId});
            refetch()
        } else {
            alert('Error saving decision')
        }
    }, [activeSkid, decisionId, game, refetch, saveTeamDecision, teamId]);
    
    const selectedDecision = useMemo(() => {
        return (teamState.selected) ? decisions?.find((d) => d.id === teamState.selected) : undefined
    }, [teamState.selected, decisions])

    const { timeLeft, hasFinished } = useCompletedAtTimer(completedAt, WAIT_AFTER_COMPLETE, false, true);
    const team = teams.find((team) =>  team.id === teamId)

    const { data: decisionData } = useSkidDecisions()
    const winnerDecisionTypes = useMemo(() => Object.values(decisionData).filter(e => Boolean(e)), [decisionData]) as Decision[]

    const gt = GradientTypes[team?.gradientType ?? 'default']
    return (
        <Stage isLoading={isSkidInitialLoading || isGameStateInitialLoading} noTop alignTop gradientColorStart={gt[0]} gradientColorEnd={gt[1]} canScroll>
            {!team && "Team existiert nicht"}
            {team && (
                <>
                    <div css={css`color: white; border-bottom: 1px solid rgba(255,255,255,0.6); margin-bottom: 20px; padding-bottom: 20px; font-weight: bold;`}>
                     {team.name} - {game?.name}
                    </div>
                    {teamState.stage === 'not-started' && (
                        <div css={css`color: white; opacity: 0.75`}>Warte auf die erste Entscheidungsrunde</div>
                    )}
                    {teamState.stage === 'completed' && (
                        <div css={css`color: white; opacity: 0.75`}>Keine Entscheidungsrunde mehr</div>
                    )}

                    {teamState.stage === 'selection' && (
                        <>
                            <h3 css={css`color: white;`}>Runde: {activeSkid?.name}</h3>
                            <div css={css`color: white; opacity: 0.75`}>Wählt einen Vorteil aus:</div>
                            <div css={css`margin-bottom: 10px; display: block`}>
                                <DecisionSelector onChange={(value) => setDecisionId(value)} decisions={decisions} winnerDecisionTypes={winnerDecisionTypes}/>
                                <button
                                    css={css`margin-left: 15px;padding: 8px; font-size:16px; border-radius: 10px; background: rgba(255,255,255, 0.5); border: 1px solid rgba(255,255,255, 0.7)`}
                                    onClick={saveTD}
                                    disabled={isSavingTeamDecision}
                                >Jetzt festlegen</button>
                            </div>
                            <div css={css`border: 6px solid rgba(255,255,255, 0.5); border-radius: 10px;`}>
                                <DecisionsContent gradientType={gt} winnerDecisionTypes={winnerDecisionTypes} />
                            </div>
                        </>
                    )}

                    {teamState.stage === 'selected' && (
                        <>
                            <h3 css={css`color: white;`}>Runde: {activeSkid?.name}</h3>
                            <div css={css`color: white; font-size: 16px`}>Ihr habt gewählt: <strong><u>{decisionCopy[selectedDecision?.type ?? '']?.name ?? selectedDecision?.type}</u></strong></div>
                            <div css={css`color: white;opacity: 0.75; margin-top: 20px;`}>... warte auf user runde</div>
                        </>
                    )}

                    {teamState.stage === 'user-skidding-in-progress' && (
                        <>
                            <h3 css={css`color: white;`}>Runde: {activeSkid?.name}</h3>
                            <div css={css`color: white; font-weight: bold; font-size: 15px; animation: timer 1s linear 0s infinite normal forwards; width: 300px`}>
                                Feuert Eure Fans an!!
                            </div>
                            <div css={css`color: white; font-size: 12px; margin-top: 20px; opacity: 0.8`}>
                                Ergbnis in <CountdownPlain timeLeft={timeLeft} hasFinished={hasFinished}/>
                            </div>
                        </>
                    )}

                    {teamState.stage === 'round-done' && (
                        <TeamSelectionResultScreen skidId={activeSkid?.id} teamId={teamId}/>
                    )}
                </>
            )}
        </Stage>
    );
}