import { useState } from 'react';
import { Create, Loading, SelectInput, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { SPORT_TYPES_INPUT } from '../../constant';
import { GameActions } from './GameActions';
import { GameDecisionSelectorField } from './GameDecisionSelector';

export const GameCreate = () => {
    const { id } = useParams()
    const { data, isLoading } = useGetOne('events', { id }, { enabled: Boolean(id)});
    const [sport, setSport] = useState<string | undefined>()

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Create
            title={`Add game to event ${data?.name}`}
            resource="games"
            mutationOptions={{ meta: { eventId: id }}}
            redirect={(_resource: any, _id: any, data: any) => `events/${data.event_id}/show`}
            actions={<GameActions />}
        >
            <SimpleForm>
                <TextInput source="name" required />
                <SelectInput source="type" label="Sport" choices={SPORT_TYPES_INPUT} required onChange={(e: any) => setSport(e.target.value)}/>
                {sport && <GameDecisionSelectorField sport={sport} />}
            </SimpleForm>
        </Create>
    );
}