/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';
import { CircleIcon } from './CircleIcon';

export type SkidZoneSize = 'small' | 'medium' | 'xl' | '2xl' | '3xl';

type SkidProps = {
  isActive: boolean
  onSkid: () => void
  skidZoneSize?: SkidZoneSize,
  countdownCopy?: string,
}

const order = ['left', 'top', 'right']

export const SkidThree: React.FunctionComponent<SkidProps> = ({ isActive, onSkid, skidZoneSize, countdownCopy }) => {
  const { selectedTeam } = useSkid()
  const [ currentBoxId, setCurrentFieldId ] = useState<string | undefined>(undefined)
  const leftField = useRef<HTMLDivElement>(null)
  const rightField = useRef<HTMLDivElement>(null)
  const topField = useRef<HTMLDivElement>(null)

  useEffect(() => setCurrentFieldId(undefined), [isActive])

  const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']

  function getFieldIdByPage(page: React.Touch) {
    const leftBoundingRect = leftField.current!.getBoundingClientRect()
    const rightBoundingRect = rightField.current!.getBoundingClientRect()
    const topBoundingRect = topField.current!.getBoundingClientRect()

    if (
      leftBoundingRect.left <= page.pageX &&  page.pageX <= leftBoundingRect.right
      && leftBoundingRect.top <= page.pageY && page.pageY <= leftBoundingRect.bottom
    ) {
      return 'left'
    }

    if (
      rightBoundingRect.left <= page.pageX &&  page.pageX <= rightBoundingRect.right
      && rightBoundingRect.top <= page.pageY && page.pageY <= rightBoundingRect.bottom
    ) {
      return 'right'
    }

    if (
      topBoundingRect.left <= page.pageX &&  page.pageX <= topBoundingRect.right
      && topBoundingRect.top <= page.pageY && page.pageY <= topBoundingRect.bottom
    ) {
      return 'top'
    }

    return currentBoxId
  }

  function canSkidToNextBox(currentBoxId: string | undefined, nextBoxId: string | undefined | null) {
    if (!currentBoxId || !nextBoxId || currentBoxId ===  nextBoxId) {
      return false
    }
    const currentIndex = order.findIndex((e) => e === currentBoxId)
    const next = (currentIndex + 1) === order.length ? 0 : currentIndex + 1

    return nextBoxId === order[next]
  }

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isActive && canSkidToNextBox(currentBoxId, event.currentTarget.getAttribute('data-box-id'))) {
      onSkid()
      setCurrentFieldId(event.currentTarget.getAttribute('data-box-id') ?? undefined)
    }
  }, [isActive, currentBoxId, setCurrentFieldId, onSkid])

  function handleTouchStart(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    setCurrentFieldId(getFieldIdByPage(event.touches[0]));
  }

  function handleTouchMove(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    const touchBoxId = getFieldIdByPage(event.touches[0])
    
    if (event.touches.length > 0 && isActive) {
      if (currentBoxId === undefined && touchBoxId) {
        setCurrentFieldId(touchBoxId)
        return;
      }
    
      if (canSkidToNextBox(currentBoxId, touchBoxId)) {
        onSkid()
        setCurrentFieldId(touchBoxId)
      }
    }
  }

  let distance = 8;
  let top = 55;
  if (skidZoneSize === 'medium') {
    distance = 6
    top = 56
  }

  if (skidZoneSize === 'xl') {
    distance = 4
    top = 57
  }

  if (skidZoneSize === '2xl') {
    distance = 2
    top = 58
  }

  if (skidZoneSize === '3xl') {
    distance = 0
    top = 60
  }
  
  return (
    <div
        onTouchMove={handleTouchMove}
        css={css`
            opacity: ${isActive ? '1' : '0.8'};
            height: 60vw;
            position: relative;
            user-select: none;
        `}>
          {(!isActive && Boolean(countdownCopy)) && (<div css={css`opacity: 0.4; width: 50px; height: 50px; position: absolute; left: 50%; top: 50%; margin: -25px 0 0 -25px; z-index: 1000`}><CircleIcon /></div>)}
          <div
            ref={leftField}
            data-box-id="left"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              background: ${gt[0]};
              opacity: ${isActive && currentBoxId === 'left' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'left' ? `0 0 10px -5px white;` : "none"}; 
              height: 50%;
              width: 40%;
              position: absolute;
              top: ${top}%; left:${distance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
            
          ></div>
          {/* <div css={css`
            width: ${skidZonwWidth}px;
            background-color: #ffffff;
            opacity: 0.1;
            background: repeating-linear-gradient( 45deg, #000000, #000000 2px, #ffffff 2px, #ffffff 10px );
          `}/> */}
          <div
            ref={topField}
            data-box-id="top"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'top' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId !== 'top' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
              height: 50%;
              width: 40%;
              position: absolute;
              top: 0; left:30.5%
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
          <div
            ref={rightField}
            data-box-id="right"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'right' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'right' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
              height: 50%;
              width: 40%;
              position: absolute;
              top: ${top}%; right:${distance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
          
          {!isActive && countdownCopy && (
            <div css={css`
              text-align: center;
              color: white;
              font-weight: bold;
              font-size: 30px;
              animation: readySetGo 1s linear 0s infinite normal forwards;
              position: absolute;
              top: 0; left: 0; bottom: 0; right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              pointer-events: none
            `}
            ><span>{countdownCopy}</span>
            </div>
          )}
    </div>

  );
}