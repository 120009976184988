import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCountdownTimer } from '../hooks/useCountdownTimer';
import { useGamePageRouter } from '../hooks/usePageRouter';
import { useSkid } from '../hooks/useSkid';
import { Introduction } from '../warmup/Introduction';
import { StepCompleted } from '../warmup/StepCompleted';
import { SwipeWithScore } from '../warmup/SwipeWithScore';
import { SwipeWithScoreFour } from '../warmup/SwipeWithScoreFour';
import { SwipeWithScoreThree } from '../warmup/SwipeWithScoreThree';

export const WarmUp = ({ test }: {test?: boolean}) => {
    const { selectedTeam, hasCompletedWarmUp, completeWarmup, skidZoneSize } = useSkid();
    const { createRoute } = useGamePageRouter();
    const [warmUpStage, setWarmUpStage] = useState<number>(0);
    const [skidCount, setSkidCount] = useState<number>(0);
    const { startCountdown, isActive: isCountdownActive, timeLeft, hasFinished } = useCountdownTimer(5);

    const navigate = useNavigate();
    useEffect(() => {
        if (!test && !selectedTeam) {
            navigate(createRoute('select-team'));
        }
        if (!test && hasCompletedWarmUp && warmUpStage === 0) {
            navigate(createRoute('decisions'));
        }
    }, []);

    useEffect(() => {
        if (warmUpStage === 0 && skidCount > 0) {
            setWarmUpStage(1);
            setSkidCount(0);
            return;
        }

        if (warmUpStage === 1 && skidCount >= 2) {
            setWarmUpStage(2);
            setSkidCount(0);
            return;
        }

        if (warmUpStage === 2 && isCountdownActive && hasFinished) {
            setWarmUpStage(3);
            return;
        }

        // return () => {
        //     setWarmUpStage(0);
        //     setSkidCount(0)
        // }
    }, [skidCount, warmUpStage, setWarmUpStage, setSkidCount, startCountdown, timeLeft, hasFinished, isCountdownActive]);


    return (
        <>
            {warmUpStage === 0 && (
                <Introduction onClick={() => setWarmUpStage(1)} />
            )}
            {/* {warmUpStage === 1 && (
                <SwipeOnce onComplete={() => setWarmUpStage(2)} skidZoneSize={skidZoneSize} />
            )}
            {/* {warmUpStage === 2 && (
                <StepCompleted onClick={() => setWarmUpStage(3)} headline="Sehr gut!" description="Im nächsten Schritt, rutsche mit deinem Finger von der rechten Box zur LInken und wieder zurück." ctaCopy="Weiter" />
            )} */}

            {/* {warmUpStage === 2 && (
                <SwipeMulti onComplete={() => setWarmUpStage(4)} skidZoneSize={skidZoneSize} />
            )} */}

            {/* {warmUpStage === 4 && (
                <StepCompleted onClick={() => setWarmUpStage(5)} headline="Solide!" description="Als nächstes rutsche mit deinem Finger 5 Sekunden lang zwischen den Boxen hin und her." ctaCopy="Weiter" />
            )}  */}

            {/* {warmUpStage === 1 && (
                <SwipeWithinTime onComplete={() => setWarmUpStage(2)} skidZoneSize={skidZoneSize} timeLimit={5} />
            )} */}

            {/* {warmUpStage === 6 && (
                <StepCompleted onClick={() => setWarmUpStage(7)} headline="Ok, Ok!" description="Jetzt rutsche innerhalb von 5 Sekunden so oft wie möglich hin und her. Klicke auf 'Skid it!' und wir zählen mit." ctaCopy="Weiter" />
            )} */}

            {warmUpStage === 1 && (
                <SwipeWithScore onComplete={() => setWarmUpStage(2)} skidZoneSize={skidZoneSize} timeLimit={5} />
            )}

            {/* {warmUpStage === 2 && (
                <StepCompleted onClick={() => setWarmUpStage(3)} headline="Sehr gut!" description="Im nächsten Schritt fügen wir eine Box hinzu. Swipe in einem Kreis um den Zähler nach oben zu treiben. " ctaCopy="Weiter" />
            )} */}

            {warmUpStage === 2 && (
                <SwipeWithScoreThree onComplete={() => setWarmUpStage(3)} skidZoneSize={skidZoneSize} timeLimit={5} />
            )}

            {/* {warmUpStage === 4 && (
                <StepCompleted onClick={() => setWarmUpStage(5)} headline="Ui Ui!" description="Na gut, lass uns noch eine Box hinzufügen. Mal gucke was für ein Score Du jetzt erreichts." ctaCopy="Weiter" />
            )} */}

            {warmUpStage === 3 && (
                <SwipeWithScoreFour onComplete={() => setWarmUpStage(4)} skidZoneSize={skidZoneSize} timeLimit={5} />
            )}

            {warmUpStage === 4 && (
                <StepCompleted onClick={() => setWarmUpStage(5)} headline="Das ist Skidden!" description="Skidde bei Entscheidungsrunden so schnell wie möglich um dein Team zu unterstützen!" ctaCopy="Weiter" />
            )}

            {warmUpStage === 5 && (
                <StepCompleted onClick={() => { if (!test) {completeWarmup(); navigate(createRoute('decisions'));} }} showActionCards headline="Warum sollen wir Skidden?" description="Jedes Team wählt vor jeder Runde eine 'Action-Card' aus. Nur das Team, wessen Fangruppe im Skidden gewinnt, darf die Karte ausspielen." ctaCopy="Alles klar!" />
            )}
        </>
    );
};
