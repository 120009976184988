/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';
import { DecisionsContent } from './DecisionsContent';

export const DecisionsDescriptionOverlay: React.FunctionComponent<{ onClose?: () => void}> = ({ onClose }) => {
  const { selectedTeam } = useSkid();
  const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
  return (
    <div css={css`
      position: fixed;
      border: 6px solid rgba(255,255,255, 0.5);
      top:30%;
      margin-top: -100px;
      left: 30px;
      right: 30px;
      border-radius: 10px;
      z-index: 100000;
    `}>
      <div css={css`overflow-x: hidden; height: 430px;`}>
        <DecisionsContent gradientType={gt} />
      </div>
      {onClose && (
        <span
          onClick={onClose}
          css={css`color: black; background: rgba(255,255,255, 0.5); padding: 5px; position: absolute; top: -35px; right: 0px; text-decoration: underline`}>
            schließen
        </span>
      )}
    </div>
  );
}