import { ArrowBack } from '@mui/icons-material';
import { Button } from "react-admin";
import { Link, useParams } from "react-router-dom";

export const BackToEventButton = () => {
    const { id } = useParams();

    return (
        <Button
            component={Link}
            to={`/events/${id}/show`}
            label="Back to event"
            startIcon={<ArrowBack />}
        />
    );
};