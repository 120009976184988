/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import { updateGameState } from "../client/skid-worker"
import { CountdownPlain } from "../core/CountdownPlain"
import { DoubleOptInButton } from "../core/DoubleOptInButton"
import { CRUNCH_TIME_BEFORE_COMPLETE, WAIT_AFTER_COMPLETE } from "../decisions/HotMinute"
import { RefereeResultScreen } from "../decisions/RefereeResultScreen"
import { SelectedDecisions } from "../decisions/SelectedDecisions"
import { useCompletedAtTimer } from "../hooks/useCountdownTimer"
import { Skid } from "../hooks/useEvent"

export const RefereeSkidControlItem: React.FunctionComponent<{
  skid: Skid
  gameId: string,
  isActive: boolean
  isComplete: boolean
  stage: string
  index: number
  completeRateTeams?: number
  completedAt?: Date
}> = ({ skid, isActive, isComplete, stage, completeRateTeams, gameId, completedAt }) => {
  // update!
  const queryClient = useQueryClient()
  const { mutateAsync: saveState, isLoading: isUpdatingState } = useMutation({
    mutationFn: async (stage: 'user' | 'team') => {
      return await updateGameState(gameId, skid.id, stage)
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['gameState', gameId], data)
    },
    onError: () => {
      alert('Error saving state')
    }
  })
  
  const { timeLeft: timeTillHotMinute, isActive: isActivePreTime, hasFinished: hasFinishedPreTime } = useCompletedAtTimer(completedAt, -1 * CRUNCH_TIME_BEFORE_COMPLETE, false, true)
  const { timeLeft, hasFinished, isActive: isCrunchTimeActive } = useCompletedAtTimer(completedAt, WAIT_AFTER_COMPLETE, false, true)
  const onEnableTeam = useCallback(() => {
    saveState('team')
  }, [saveState])

  const onEnableUsers = useCallback(() => {
    saveState('user')
  }, [saveState])

  // waiting
  let team = <>-</>
  let user = <>-</>
  
  // I am over
  if (isComplete) {
      team = skid.can_teams_select ? <SelectedDecisions cta={"view decisions"} skidId={skid.id} /> : team;
      user = <RefereeResultScreen cta={"view results"} skidId={skid.id}/>
    } else if (isActive) {
      if (stage === 'enable-decision-selection') {
        team = <DoubleOptInButton copy="für teams freigeben" gradientType="default" size="small" onClick={onEnableTeam} isLoading={isUpdatingState} />
      }

      if (stage === 'team-selection-in-progress') {
        team = (<>
          <span>{(Math.round((completeRateTeams ?? 0)*1000) / 10)}%</span>
          <SelectedDecisions cta={"check"} skidId={skid.id} />
        </>);
      }
      
      if (stage === 'team-selection-finished') {
        team = skid.can_teams_select ? (<>
          <span>100%</span>
          <SelectedDecisions cta={"check"} skidId={skid.id} />
        </>) : team;
        user = <DoubleOptInButton copy="für user freigeben" gradientType="default" size="small" onClick={onEnableUsers} isLoading={isUpdatingState} />
      }

      if (stage === 'user-skidding-in-progress') {
        team =skid.can_teams_select ? (<>
          <span>100%</span>
          <SelectedDecisions cta={"check"} skidId={skid.id} />
        </>) : team;;
        user = (
          <>
            {isActivePreTime && !hasFinishedPreTime && <><CountdownPlain timeLeft={timeTillHotMinute} hasFinished={hasFinishedPreTime} /> bis Crunch Time</>}
            {hasFinishedPreTime && isCrunchTimeActive && <><CountdownPlain timeLeft={timeLeft} hasFinished={hasFinished} /> CRUNCH TIME</>}
            {hasFinishedPreTime && hasFinished && <>...warte</>}
          </>
        )
      }
    }

    return (
      <tr>
          <td css={css`color: white; font-size: 14px;font-weight: bold; opacity: 0.6; padding: 0 10px; border-bottom: 1px solid rgba(255,255,255,0.2)`}>{skid.name}</td>
          <td css={css`color: rgba(255,255,255,0.6); padding: 0 10px; height: 60px; border-bottom: 1px solid rgba(255,255,255,0.2)`}>{team}</td>
          <td css={css`color: rgba(255,255,255,0.6); padding: 0 10px; height: 60px; border-bottom: 1px solid rgba(255,255,255,0.2)`}>{user}</td>
      </tr>
    );

}