import { EditButton, ListButton, Show, SimpleShowLayout, TextField, TopToolbar } from "react-admin";

const DecisionShowActions = () =>(
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
)

export const DecisionShow = () => (
    <Show queryOptions={{ meta: { includeEvents: true }}} actions={<DecisionShowActions />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="sport" />
            <TextField source="description" label="Beschreibung" />
        </SimpleShowLayout>
    </Show>
);