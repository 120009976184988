import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";
import { useParams } from "react-router-dom";
import { EditSkidButton } from "./EditSkidButton";
import { SkidActions } from "./SkidActions";
import { SkidTitle } from "./SkidTitle";

export const SkidShow = () => {
    const { id, gameId, skidId } = useParams();

    return (
        <Show
            resource="skids"
            id={skidId}
            queryOptions={{ meta: { eventId: id, gameId }}}
            actions={<SkidActions extra={[<EditSkidButton />]} />}
            title={<SkidTitle />}
        >
            <SimpleShowLayout>
                <TextField source="id" />
                <NumberField source="order_number" label="Rundennummer"  />
                <TextField source="name" />
                <TextField source="type" />
                <BooleanField source="can_teams_select" label="Teams need to select decision?" />
            </SimpleShowLayout>
        </Show>
    )    
};