/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { Stage } from '../core/Stage';
import { useCountdownTimer } from '../hooks/useCountdownTimer';
import { Skid as SkidType } from '../hooks/useEvent';
import { useSaveSkid } from '../hooks/useSaveSkid';
import { useSkid } from '../hooks/useSkid';
import { useTeamDecisionSelection } from '../hooks/useTeamDecisionSelection';
import { Skid } from '../skid/Skid';
import { SkidHeader } from '../skid/SkidHeader';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidThree } from '../skid/SkidThree';
import { DecisionDescriptionHeadCTA } from './DecisionDescriptionHeadCTA';
import { SkidFour } from '../skid/SkidFour';

export const PreparationTime = ({ isTest, isRoundOver, skid }: { isTest?: boolean, isRoundOver?: boolean, skid?: SkidType }) => {
    const { selectedTeam, isInitialLoading, decisionCopy } = useSkid();
    const { createTestSkid } = useSaveSkid();
    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    const teamSelectionForRound = useTeamDecisionSelection()
    
    const { startCountdown, display, isActive: isCountdownActive, isTimerActive, timeLeft, timeLeftDisplay, hasFinished, resetCountdown } = useCountdownTimer(5)
    const [skidCount, setSkidCount] = useState<number>(0)

    useEffect(() => {
        if (isCountdownActive && hasFinished && timeLeft === 0 && !isTest) {
            createTestSkid({ score: skidCount, type: 'swipe' })
        }
    }, [hasFinished, isCountdownActive, createTestSkid, skidCount, timeLeft, isTest])
    
    let bottom = undefined;
    if (!isCountdownActive && !display) {
        bottom = <Button copy="Training starten" onClick={() => {
            startCountdown();
        }} gradiendDegree={90}/>
    }

    if (isCountdownActive && hasFinished) {
        bottom = <Button copy="Reset" onClick={() => {
            setSkidCount(0);
            resetCountdown();
        }} gradiendDegree={90}/>
    }

    return (
        <Stage isLoading={isInitialLoading} gradiendDegree={0} gradientColorStart={gt[0]} gradientColorEnd={gt[1]} bottom={bottom} alignTop={true} top={<DecisionDescriptionHeadCTA />}>
            <SkidHeader>
                {hasFinished || !isCountdownActive ? (
                    <>
                    {!isRoundOver && teamSelectionForRound ? (
                        <>
                        <div css={css`display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%;`}>
                            <div css={css`font-size:16px; font-weight: bold; opacity: 0.8; margin-bottom: 5px;`}>
                                Dein Team hat gewählt:
                            </div>
                        </div>
                        <div css={css`font-size:20px; font-weight: bold; text-align: center`}>
                            <u>"{decisionCopy[teamSelectionForRound.type]?.name ?? teamSelectionForRound.type}"</u>
                        </div>
                    </>
                    ) : (
                        <div css={css`display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%;`}>
                            {true && (<svg
                                css={css`
                                    fill: white;
                                    width: 40px;
                                    height: 40px;
                                    margin-right: 10px;
                                    opacity: 0.75;
                                `}
                                xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-120q-75 0-140.271-28.4-65.271-28.401-114.1-77.229-48.828-48.829-77.229-114.1Q120-405 120-480q0-42.268 10-82.134t29-76.366q19-36.5 45.5-68T265-763l268 268-42 43-230-230q-39 42-60 93.5T180-480q0 125 87.5 212.5T480-180q125 0 212.5-87.5T780-480q0-118-79-204.5T504-779v100h-60v-161h34q74.722 0 140.861 28.5T734-734.5q49 48.5 77.5 114T840-480q0 75-28.4 140.271-28.401 65.271-77.229 114.1-48.829 48.828-114.1 77.229Q555-120 480-120Zm-1.895-103Q462-223 451-233.895q-11-10.894-11-27Q440-277 450.895-288q10.894-11 27-11Q494-299 505-288.105q11 10.894 11 27Q516-245 505.105-234q-10.894 11-27 11Zm221-221Q683-444 672-454.895q-11-10.894-11-27Q661-498 671.895-509q10.894-11 27-11Q715-520 726-509.105q11 10.894 11 27Q737-466 726.105-455q-10.894 11-27 11Zm-439 0Q244-444 233-454.895q-11-10.894-11-27Q222-498 232.895-509q10.894-11 27-11Q276-520 287-509.105q11 10.894 11 27Q298-466 287.105-455q-10.894 11-27 11Z"/></svg>
                            )}
                            <div>
                                <div css={css`font-size:30px; font-weight: bold`}>
                                    Trainiere Skidden
                                </div>
                            </div>
                        </div>
                    )}
                    </>
                ): null}
                {isCountdownActive && !hasFinished ? (
                    <div css={css`display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%;`}>
                        <div css={css`font-size: 40px; font-weight: bold;`}>
                            Skid Training
                        </div>
                    </div>
                ): null}
            </SkidHeader>
            <SkidScoreBoard left={skidCount} right={timeLeftDisplay} leftLabel='Dein Score' rightLabel='Zeit' rightSuffix=' s' />
            <div css={css`margin-top: 40px`}>
                {['swipe', 'two'].includes(skid?.type ?? '') && (
                    <Skid isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={'medium'} countdownCopy={display} />
                )}
                {['three'].includes(skid?.type ?? '') && (
                    <SkidThree isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={'xl'} countdownCopy={display} />
                )}
                {['four'].includes(skid?.type ?? '') && (
                    <SkidFour isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={'medium'} countdownCopy={display} />
                )}
            </div>
        </Stage>
    )       
}