/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { Stage } from "../core/Stage";
import { useCountdownTimer } from '../hooks/useCountdownTimer';
import { Skid } from '../skid/Skid';
import { SkidFour } from '../skid/SkidFour';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidThree } from '../skid/SkidThree';

export const Test = () => {
    const [skidCount, setSkidCount] = useState<number>(0)
    const [skidZoneSize, setSkidZoneSize] = useState<'small' | 'medium' | 'xl' | '2xl' | '3xl'>('small')
    const [skidType, setSkidType] = useState<'two' | 'three' | 'four' >('two')
    const { startCountdown, display, isActive: isCountdownActive, isTimerActive, timeLeftDisplay, hasFinished, resetCountdown } = useCountdownTimer(5)

    const gt = GradientTypes['default']

    let bottom = undefined;
    if (!isCountdownActive && !display) {
        bottom = <Button copy='Start' onClick={() => {
            startCountdown();
        }} gradiendDegree={90}/>
    }

    if (isCountdownActive && hasFinished) {
        bottom = <Button copy='Reset' onClick={() => {
            setSkidCount(0);
            resetCountdown();
        }} gradiendDegree={90}/>
    }

    return (
        <Stage
            gradiendDegree={-90}
            gradientColorStart={gt[0]}
            gradientColorEnd={gt[1]}
            bottom={bottom}
            alignTop={true}
        >
            <SkidScoreBoard left={skidCount} right={timeLeftDisplay} leftLabel='Dein Score' rightLabel='Zeit' rightSuffix=' s' />
            
            <div css={css`margin-top: 40px`}>
                {skidType === 'two' && (
                    <Skid isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={skidZoneSize} countdownCopy={isCountdownActive && display ? display : undefined} />
                )}
                {skidType === 'three' && (
                    <SkidThree isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={skidZoneSize} countdownCopy={isCountdownActive && display ? display : undefined} />
                )}
                {skidType === 'four' && (
                    <SkidFour isActive={isTimerActive} onSkid={() => {
                        if (isTimerActive) {
                            setSkidCount(skidCount + 1)
                            return;
                        }
                    }} skidZoneSize={skidZoneSize} countdownCopy={isCountdownActive && display ? display : undefined} />
                )}
            </div>

            <div css={css`
                text-align: center;
                color: white;
                margin-top: 40px;
                
                font-weight: bold;
                font-size: 20px;

                display: flex;
                justify-content: space-between;
            `}>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidZoneSize('small')}>small</button>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidZoneSize('medium')}>medium</button>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidZoneSize('xl')}>xl</button>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidZoneSize('2xl')}>2xl</button>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidZoneSize('3xl')}>3xl</button>
            </div>
            <div css={css`
                text-align: center;
                color: white;
                margin-top: 10px;
                
                font-weight: bold;
                font-size: 20px;

                display: flex;
            `}>
                <button css={css`width: 18%; padding:5px; margin-right: 5px`} onClick={() => setSkidType('two')}>two</button>
                <button css={css`width: 18%; padding:5px; margin-right: 5px`} onClick={() => setSkidType('three')}>three</button>
                <button css={css`width: 18%; padding:5px`} onClick={() => setSkidType('four')}>four</button>
            </div>
        </Stage>
    );
}