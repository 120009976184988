/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';

export type SkidZoneSize = 'small' | 'medium' | 'xl' | '2xl' | '3xl';

type SkidProps = {
  isActive: boolean
  onSkid: () => void
  skidZoneSize?: SkidZoneSize,
  countdownCopy?: string,
}

export const Skid: React.FunctionComponent<SkidProps> = ({ isActive, onSkid, skidZoneSize, countdownCopy }) => {
  const { selectedTeam } = useSkid()
  const [ currentBoxId, setCurrentFieldId ] = useState<string | undefined>(undefined)
  const leftField = useRef<HTMLDivElement>(null)
  const rightField = useRef<HTMLDivElement>(null)

  useEffect(() => setCurrentFieldId(undefined), [isActive])

  const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']

  function getFieldIdByPage(page: React.Touch) {
    const leftBoundingRect = leftField.current!.getBoundingClientRect()
    const rightBoundingRect = rightField.current!.getBoundingClientRect()

    if (
        leftBoundingRect.left <= page.pageX &&  page.pageX <= leftBoundingRect.right
        && leftBoundingRect.top <= page.pageY && page.pageY <= leftBoundingRect.bottom
      ) {
        return 'left'
      }

      if (
        rightBoundingRect.left <= page.pageX &&  page.pageX <= rightBoundingRect.right
        && rightBoundingRect.top <= page.pageY && page.pageY <= rightBoundingRect.bottom
      ) {
        return 'right'
      }

      return currentBoxId
  }

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isActive && currentBoxId !== event.currentTarget.getAttribute('data-box-id')) {
      onSkid()
      setCurrentFieldId(event.currentTarget.getAttribute('data-box-id') ?? undefined)
    }
  }, [isActive, currentBoxId, setCurrentFieldId, onSkid])

  function handleTouchStart(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    setCurrentFieldId(getFieldIdByPage(event.touches[0]));
  }

  function handleTouchMove(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    const touchBoxId = getFieldIdByPage(event.touches[0])
    if (
      event.touches.length > 0
      && isActive
      && currentBoxId !== touchBoxId
    ) {
        if (currentBoxId !== undefined) {
          onSkid()
        }
        setCurrentFieldId(touchBoxId)
    }
  }

  let skidZonwWidth = 0;
  if (skidZoneSize === 'medium') {
    skidZonwWidth = 25
  }

  if (skidZoneSize === 'xl') {
    skidZonwWidth = 50
  }

  if (skidZoneSize === '2xl') {
    skidZonwWidth = 75
  }

  if (skidZoneSize === '3xl') {
    skidZonwWidth = 100
  }
  
  return (
    <div
        onTouchMove={handleTouchMove}
        css={css`
            display: flex;
            flex-direction: row;
            opacity: ${isActive ? '1' : '0.8'};
            height: 50vw;
            position: relative;
            user-select: none;
        `}>
          <div
            ref={leftField}
            data-box-id="left"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              background: ${gt[0]};
              opacity: ${isActive && currentBoxId === 'left' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'left' ? `0 0 10px -5px white;` : "none"}; 
              flex-grow: 1;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
            
          ></div>
          <div css={css`
            width: ${skidZonwWidth}px;
            background-color: #ffffff;
            opacity: 0.1;
            background: repeating-linear-gradient( 45deg, #000000, #000000 2px, #ffffff 2px, #ffffff 10px );
          `}/>
          <div
            ref={rightField}
            data-box-id="right"
            css={css`
              flex-grow: 1;
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'right' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'left' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
          {!isActive && countdownCopy && (
            <div css={css`
              text-align: center;
              color: white;
              font-weight: bold;
              font-size: 30px;
              animation: readySetGo 1s linear 0s infinite normal forwards;
              position: absolute;
              top: 0; left: 0; bottom: 0; right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              pointer-events: none
            `}
            ><span>{countdownCopy}</span>
            </div>
          )}
    </div>

  );
}