/** @jsxImportSource @emotion/react */

import { useParams } from "react-router-dom";
import { Cooldown } from "../decisions/Cooldown";
import { HotMinute } from "../decisions/HotMinute";
import { NoDecision } from "../decisions/NoDecision";
import { PreparationTime } from "../decisions/PreparationTime";
import { Skid } from "../hooks/useEvent";
import { Dashboard } from "./Dashboard";

export const TestDecision = () => {
    const params = useParams()
    switch(params.type) {
        case 'none':
            return <NoDecision />
        case 'prep':
            return <PreparationTime isTest={true}/>

        case 'hot-minute':
            return <HotMinute
                skid={{
                    name: "Test Entscheidung",
                    // description: "orem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.",
                    id: 'training',
                } as Skid}
                isTest
                completedAt={new Date((new Date()).getTime() + 25 * 1000)}
                onHotMinuteEnd={() => { console.log('end test')}}
            />
        
        case 'cooldown':
            return <Cooldown completedAt={new Date((new Date()).getTime() + 40 * 1000)} onCooldownEnd={() => { console.log('end')}}/>

        case 'dashboard':
            return <Dashboard isTest={true} />
    }

    return <div>please provide type</div>
}