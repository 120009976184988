/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { Stage } from '../core/Stage';
import { useCountdownTimer } from '../hooks/useCountdownTimer';
import { useSkid } from '../hooks/useSkid';
import { CircleIcon } from '../skid/CircleIcon';
import { SkidZoneSize } from '../skid/Skid';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidThree } from '../skid/SkidThree';

export const SwipeWithScoreThree = ({ onComplete, skidZoneSize, timeLimit}: {onComplete: () => void, skidZoneSize: SkidZoneSize, timeLimit: number }) => {
    const { selectedTeam } = useSkid();
    const [skidCount, setSkidCount] = useState<number>(0)
    const { startCountdown, display, isActive, isTimerActive, timeLeftDisplay, hasFinished } = useCountdownTimer(timeLimit, false)

    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    let button = null;
    if (hasFinished) {
        button = <Button copy="Weiter" onClick={onComplete} gradiendDegree={189}/>
    }

    if (!isActive) {
        button = <Button copy='Skid it!' onClick={() => startCountdown()} gradiendDegree={90}/>
    }

    return (
        <Stage
            gradiendDegree={-90} gradientColorStart={gt[0]} gradientColorEnd={gt[1]}
            bottom={button}
            top={hasFinished ? (
                <div
                    css={css`
                        font-weight: normal;
                        font-size: 18px;
                        color: white;
                        font-weight:bold;
                        text-align: center;
                    `}
                >
                    Auch das hast Du verstanden
                </div>
            ) : (isActive ? null :(
                <div css={css`font-weight: normal;font-size: 20px; color: white; text-align: center; margin-bottom: 20px; opacity: 0.8; padding: 0 20px;`}>
                Jetzt mit <strong>3 Boxen</strong>. Du musst nacheinander die Boxen treffen.
            </div>
            ))}
            noTop
        >
            
            <div css={css`margin-bottom: 40px`}>
                <SkidScoreBoard left={skidCount} right={timeLeftDisplay} leftLabel='Dein Score' rightLabel='Zeit' rightSuffix=' s' />
            </div>
            <div css={css`position: relative`}>
                {!isActive && (<div css={css`z-index: 500;position: absolute; margin: -25px 0 0 -25px;top: 50%; left: 50%;width: 50px; height: 50px; opacity: 0.6;`}><CircleIcon /></div>)}
                <SkidThree countdownCopy={display} isActive={isTimerActive} onSkid={() => setSkidCount(skidCount + 1)} skidZoneSize={'xl'} />
            </div>
        </Stage>
    )       
}