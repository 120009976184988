/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { useGameState } from '../hooks/useGameState';
import { useSkid } from '../hooks/useSkid';

export const SelectedDecisionOverlay: React.FunctionComponent<{ skidId: string, onClose?: () => void}> = ({ skidId, onClose }) => {
  const { decisions, teams, game, decisionCopy } = useSkid();
  const { state } = useGameState(game);
  const { decisionSelections } = state ?? {}
  const gt = GradientTypes['default']

  return (
    <div css={css`
      position: fixed;
      border: 6px solid rgba(255,255,255, 0.5);
      top: 0;
      margin-top: 40px;
      left: 30px;
      right: 30px;
      border-radius: 10px;
      z-index: 100000;
    `}>
      <div css={css`padding: 10px; overflow-x: hidden; height: 430px;background: ${gt[0]}; background: linear-gradient(110deg, ${gt[0]} -30%, ${gt[1]} 130%);`}>
        {Object.entries(decisionSelections ?? {}).map(([teamId, value]) => {
          const team = teams.find((t) => t.id === teamId)
          const decision = (decisions ?? []).find((d) => d.id === value[skidId])
          return (
            <div key={team?.id} css={css`margin-bottom: 15px; border-bottom: 1px solid white; padding-bottom: 15px; color: white`}>
                <small css={css`margin-bottom: 3px; display: block`}>Team: {team?.name}</small>
                <span>Auswahl: <strong>{(decisionCopy[decision?.type ?? '']?.name ?? decision?.type) ?? 'Noch keine getroffen'}</strong></span>
            </div>
          )
        })}
      </div>
      {onClose && (
        <span
          onClick={onClose}
          css={css`color: black; background: rgba(255,255,255, 0.5); padding: 5px; position: absolute; top: -35px; right: 0px; text-decoration: underline`}>
            schließen
        </span>
      )}
    </div>
  );
}