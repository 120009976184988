/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSkid } from '../hooks/useSkid';
import { ResultUser } from '../hooks/useSkidResult';

export const SkidResultTeamTable: React.FunctionComponent<{
  tableData: ResultUser[],
  teamName: string,
}> = ({ tableData, teamName }) => {
  const { currentUser } = useSkid()
  return (<>
    <div css={css`margin-top: 30px; font-family: "amboy-black", sans-serif; font-size: 20px; letter-spacing: 1px; text-align: center; color: white;`}>Scoreboard - {teamName}</div>
    <div css={css`
        margin-top: 10px;
        overflow-y: scroll;
        height: 175px;
        position: relative;
        & td {
            color: rgba(255,255,255, 0.6);
            border-bottom: 1px rgba(255,255,255, 0.5) solid;
            padding: 10px 2px;
            vertical-align: middle;
        }
        
        & table {
            border-spacing: 0;
            border-collapse: collapse; 
            width: 100%;
            position: relative;
        }
        & th {
            position: sticky;
            top: 0; /* Don't forget this, required for the stickiness */
            box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
            text-align: left;
            font-weight: bold;
            font-size: 12px;
            color: white;
        }
        & td:last-child {
            text-align: right;
            font-family: monospace;
            font-size: 18px
        }

        & th:last-child {
            text-align: right;
        }

        & .currentUser td { color: white !important; font-weight: bold; }
    `}>
        <table>
            <thead>
                <tr>
                    <th>rank</th>
                    <th>name</th>
                    <th>total score</th>
                </tr>
            </thead>
            <tbody>
                {(tableData ?? []).map((user, index) => (
                    <tr key={`user-${index}`} className={user.id as unknown as string === currentUser?.id ? 'currentUser': ''}>
                        <td>{index + 1}</td>
                        <td>{user.name as unknown as string}</td>
                        <td>{user.totalScore as unknown as string}</td>
                    </tr>
                ))}
            </tbody>
        </table>
      </div>
    </>

  );
}