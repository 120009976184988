import { Button, Link, useRecordContext } from "react-admin";
import { useParams } from "react-router-dom";

export const ShowGameButton = () => {
    const record = useRecordContext();
    const param = useParams()

    return (
        <Button
            component={Link}
            to={`/events/${param.id}/games/${record?.id ?? param.gameId}/show`}
            label="show"
            key={2}
        />
    );
};