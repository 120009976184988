import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import {
  CSSTransition,
  TransitionGroup
} from "react-transition-group";
import { GameLoader } from './GameLoader';
import { Root } from './admin/root';
import { Dashboard } from './page/Dashboard';
import { Decisions } from './page/Decisions';
import { EventAdmin } from './page/EventAdmin';
import { Events } from './page/Events';
import { Login } from './page/Login';
import { SchiriBoard } from './page/SchiriBoard';
import { SelectTeam } from './page/SelectTeam';
import { TeamDecisionSelections } from './page/TeamDecisionSelections';
import { Test } from './page/Test';
import { TestDecision } from './page/TestDecision';
import { WarmUp } from './page/WarmUp';

function AnimationApp() {
  let location = useLocation();
  
  return (
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
        >
          <Routes location={location}>
            <Route path="/" element={<Events />} key="events" />
            <Route path="/events/:eventId/games/:gameId" element={<GameLoader><Login /></GameLoader>} key="select-nickname" />
            <Route path="/events/:eventId/games/:gameId/select-team" element={<GameLoader><SelectTeam /></GameLoader>} key="select-team" />
            <Route path="/events/:eventId/games/:gameId/warm-up" element={<GameLoader><WarmUp /></GameLoader>} key="warm-up" />
            <Route path="/events/:eventId/games/:gameId/decisions" element={<GameLoader><Decisions /></GameLoader>} key="decisions" />
            <Route path="/events/:eventId/games/:gameId/dashboard" element={<GameLoader><Dashboard /></GameLoader>} key="dashboard" />
            <Route path="/events/:eventId/games/:gameId/schiri" element={<GameLoader><SchiriBoard /></GameLoader>} key="referee" />
            <Route path="/events/:eventId/games/:gameId/teams/:teamId" element={<GameLoader><TeamDecisionSelections /></GameLoader>} key="teams" />
            <Route path="/events/:eventId/admin" element={<EventAdmin />} key="teams" />

            <Route path="/test" element={<Test />} key="test" />
            <Route path="/test-decision/:type" element={<TestDecision />} key="testDecision" />
            <Route path="/test-warm-up" element={<WarmUp test={true} />} key="testDecision" />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
  );
}
const queryClient = new QueryClient()

export default function App() {
  const isAdmin = /^\/admin/.test(new URL(window.location.href).pathname)
  return (
    <QueryClientProvider client={queryClient}>
      {isAdmin ? <Root /> : <BrowserRouter><AnimationApp /></BrowserRouter>}      
    </QueryClientProvider>
  );
}