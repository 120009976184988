import { useMutation } from "react-query";
import { selectGameTeam } from "../client/skid-worker";

export type TeamData = {
  team_id: string,
  game_id: string,
  created_at: string,
  teams: {
    name: string
    created_at: string
    updated_at: string
  }
}

export type Team = {
  id: string
  name: string
  gradientType: string
}

export function useSelectTeam() {
  const { mutateAsync: selectTeam, isLoading: isSavingTeaamSelection } = useMutation({
    //@ts-ignore
    mutationFn: async (data: { userId: string, gameId: string, teamId: string }): Promise<TeamData | string> => {
      const result = await selectGameTeam(data).catch((error) => {
        const response = error.response
        if (response.data && error.response.data.reason) {
          return  error.response.data.reason
        }
        return 'Unknown error occcurred.';
      })

      return result
    }
  });

  return {
    selectTeam,
    isSavingTeaamSelection
  }
}