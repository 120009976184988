/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const zeroPad = (num: number, places: number): string => String(num).padStart(places, '0')

export const CountdownPlain: React.FunctionComponent<{
  timeLeft: number;
  hasFinished?: boolean
}> = ({ hasFinished, timeLeft }) => {
  return (
    <span css={css`font-family: monospace;`}>
      {!hasFinished ? `${Math.floor(timeLeft / 60)}:${zeroPad(timeLeft % 60, 2)}` : `0:00`} min
    </span>
  );
}