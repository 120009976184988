import { useMutation } from "react-query";
import { SkidType, createSkid, createTestSkid } from "../client/skid-worker";
import { useSkid } from "./useSkid";

export function useSaveSkid() {
  const { currentUser, game, selectedTeam, eventId } = useSkid()
  const { mutateAsync: _createTestSkid, isLoading: isTestSkidSaving } = useMutation({
    mutationFn: async (data: {score: number, type: SkidType}) => {
      return await createTestSkid(data.score, data.type, currentUser!.id!)
    },
    onError: () => {
      alert('Error saving skid test result')
    }
  })
 
  const { mutateAsync: _createSkid, isLoading: isSkidSaving } = useMutation({
    mutationFn: async ({ skidId, score }: { skidId: string, score: number }) => {
      return await createSkid(eventId!, game!.id, skidId, { score, user_id: currentUser!.id!, team_id: selectedTeam!.id })
    },
    onError: () => {
      alert('Error saving skid test result')
    }
  })

  return {
    createSkid: _createSkid,
    isSkidSaving,
    createTestSkid: _createTestSkid,
    isTestSkidSaving
  }
}