import { BooleanInput, DeleteWithConfirmButton, Edit, NumberInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import { SKID_TYPES_INPUT } from '../../constant';
import { ShowSkidButton } from './ShowSkidButton';
import { SkidActions } from './SkidActions';
import { SkidTitle } from './SkidTitle';

const SkidEditToolbar = () => {
    const record = useRecordContext();
    const { id, gameId } = useParams()
    return (
        <Toolbar
            sx={{ justifyContent: 'space-between'}}
        >
            <SaveButton/>
            <DeleteWithConfirmButton
                confirmTitle={`Löschen von skid runde "${record.name}"`}
                confirmContent="Möchtest du es endgültig löschen?"
                confirmColor="warning"
                translateOptions={{id: record.id, name: record.name }}
                mutationOptions={{ meta: { eventId: id, gameId }}}
                redirect={`/events/${id}/games/${gameId}/show`}
            />
        </Toolbar>
    );
};

export const SkidEdit = () => {
    const { id, gameId, skidId } = useParams()
    return (
        <Edit
            mutationMode='optimistic'
            resource='skids'
            id={skidId}
            actions={<SkidActions extra={[<ShowSkidButton />]} />}
            queryOptions={{ meta: { eventId: id, gameId }}}
            mutationOptions={{ meta: { eventId: id, gameId }}}
            redirect={(_resource: any, _id: any, data: any) => `events/${data.event_id}/games/${data.game_id}/show`}
            title={<SkidTitle prefix="Edit" />}
        >
            <SimpleForm toolbar={<SkidEditToolbar />}>
                <NumberInput source="order_number" label="Rundennummer" required />
                <TextInput source="name" required />
                <SelectInput source="type" choices={SKID_TYPES_INPUT} required />
                <BooleanInput source="can_teams_select" label="Can teams select decisions?" />
                {/* ToDo: Decisions */}
            </SimpleForm>
        </Edit>
    );
}