import { useContext } from "react";
import { SkidContext } from "../context/SkidContext";

export function useSkid() {
    const context = useContext(SkidContext);
    
    if (context === undefined) {
      throw new Error("useSkid must be used within a SkidProvider");
    }
  
    return context;
  }