import { useState } from 'react';
import { ReferenceArrayInput, SelectInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import { SPORT_TYPES_INPUT } from '../../constant';
import { GameDecisionSelectorField } from './GameDecisionSelector';

export const GameEditForm = () => {
    const game = useRecordContext();
    const [sport, setSport] = useState<string>(game.type)
    return (
        <SimpleForm>
            <TextInput source="name" required />
            <SelectInput source="type" label="Sport" choices={SPORT_TYPES_INPUT} required onChange={(e: any) => setSport(e.target.value)} />
            <ReferenceArrayInput
                source="teams"
                reference="teams"
                enableGetChoices={({ q }) => q && q.length >= 2}
            />
            <GameDecisionSelectorField sport={sport} gameId={game.id as string} />
        </SimpleForm>
    );
}