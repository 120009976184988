import { useParams } from "react-router-dom";

export function useGamePageRouter() {
  const { gameId, eventId } = useParams()

  return {
    gameId,
    eventId,
    createRoute: (path: string) => `/events/${eventId}/games/${gameId}/${path}`
  }
}