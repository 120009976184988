/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../core/Button';
import { GameTitel } from '../core/GameTitle';
import { Input } from '../core/Input';
import { Stage } from "../core/Stage";
import { useGamePageRouter } from '../hooks/usePageRouter';
import { useSkid } from '../hooks/useSkid';


export const Login = () => {
    const navigate = useNavigate()
    const { createRoute } = useGamePageRouter()
    const { currentUser, createUser, isInitialLoading, isLoading, game } = useSkid()
    const [nickname, setNickname] = useState<string>("")
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
      if (currentUser) {
        navigate(createRoute('select-team'))
      }
      
    }, [])

    return (
        <Stage isLoading={isInitialLoading || isLoading} bottom={
            <Button copy="Weiter" onClick={async () => {
                if (!nickname || nickname === "" || nickname.length > 24) {
                    setError("Bitte gebe einen Nickname an, der nicht länger als 24 Zeichen.")
                    return;
                }
                const re = await createUser(nickname) 
                if (re !== true) {
                    setError(re ? re : 'An error ocurred')
                    return;
                }
                
                navigate(createRoute('select-team'))

            }} />
        } top={<GameTitel name={game?.name}/>}>
            <div css={css`
                font-weight: bold;
                font-size: 40px;
                text-align: center;
                margin-bottom: 40px;
            `}>
                <span><img src="/skid-brand-white.png" alt="brand" width="300" css={css`
                margin-bottom: -20px
            `}/>
                </span>
            </div>
            <div css={css`
                margin-bottom: 20px;
                text-align: center;
            `}>
                <Input onChange={(value) => setNickname(value)} name='nickname' placeholder='Nickname' />
                {error && (
                    <div css={css`
                    padding: 10px 0;
                    color: white;
                    text-align: center;
                `}>
                        {error}
                    </div>
                )}
            </div>
        </Stage>
    );
}