/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { GradientTypes } from '../context/SkidContext';


export const Stage: React.FunctionComponent<{ 
    gradiendDegree?: number
    gradientColorStart?: string,
    gradientColorEnd?: string,
    children?: React.ReactNode,
    bottom?: React.ReactNode,
    top?: React.ReactNode,
    noTop?: boolean
    alignTop?: boolean
    isLoading?: boolean
    canScroll?: boolean
}> = ({ gradiendDegree, gradientColorStart, gradientColorEnd, children, bottom, top, noTop, alignTop, isLoading, canScroll }) => {
  const gcs = gradientColorStart ?? GradientTypes['default'][0];
  const gce = gradientColorEnd ?? GradientTypes['default'][1];

  useEffect(() => {
    const isScrollable = document.body.classList.contains('scrollable')
    const html = document.getElementsByTagName( 'html' )[0]
    if (!canScroll && isScrollable) {
      document.body.classList.remove('scrollable')
      html.removeAttribute('class')
    } else if (canScroll && !isScrollable) {
      document.body.classList.add('scrollable');
      html.setAttribute('class', 'scrollable')
    }
  }, [canScroll]);

  return (
    <div css={!canScroll ? css`overflow: hidden; height: 100vh`: css`height: 100%`}>
       {!isLoading && top && (
        <div
        css={css`
            position: absolute;
            top: 20px;
            right: 0; left: 0;
        `}
        >
           {top}
        </div>
      )}
      <div
        css={css`
            background: ${gcs};
            background: linear-gradient(${gradiendDegree ?? 220}deg, ${gcs} 0%, ${gce} 100%);
            margin: 0 auto;
            ${canScroll ? 'min-': ''}height: 100%;
            padding: ${noTop ? 10 : 60}px 30px 130px;
      `}>
          <div css={css`
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: ${alignTop ? 'flex-start' : 'center'};
              align-items: center;
          `}>
              {isLoading ? (<ReactLoading type="cylon" color={'#ffffff'} width={200} />) : (
               <div css={css`width: 100%`}>{children}</div> 
              )}
          </div>
      </div>
      {!isLoading && bottom && (
        <div
        css={css`
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
        >
           {bottom}
        </div>
      )}
    </div>
  );
}