/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { Stage } from "../core/Stage";
import { useSkid } from '../hooks/useSkid';
import { ResultUser, useSkidResult } from '../hooks/useSkidResult';


export const Dashboard = ({ isTest }: { isTest?: boolean}) => {
    const { isInitialLoading, teams } = useSkid()
    const { isInitialLoading: isResultLoading, result} = useSkidResult()

    const roundData = useMemo(() => {
        if (!result) {
            return []
        }

        return Object.values(result.teams)
    }, [result])

    // @ts-ignore
    const tableData: ResultUser[] = useMemo(() => {
        if (!result) {
            return []
        }
        
        const data: ResultUser[] = [];
        for (const team of teams) {
            data.push(
                //@ts-ignore
                Object.values(result.users)
                //@ts-ignore
                .filter((u) => u.teamId === team.id)
                .sort((a, b) => {
                    if (a.totalScore === b.totalScore) return 0;
        
                    return a.totalScore < b.totalScore ? 1 : -1;
                }))
        }

        data.push(
            //@ts-ignore
            Object.values(result.users)
            .sort((a, b) => {
                if (a.totalScore === b.totalScore) return 0;
    
                return a.totalScore < b.totalScore ? 1 : -1;
            }))
        return data;
    }, [result, teams])

        
        const gt = GradientTypes['default']
        return (
            <Stage
                isLoading={isInitialLoading || isResultLoading}
                alignTop={true}
                gradiendDegree={0} gradientColorStart={gt[0]} gradientColorEnd={gt[1]}
            >
                 <div css={css`
                    padding-bottom: 40px;
                    max-width: 600px;
                    & td {
                        color: rgba(255,255,255, 0.6);
                        border-bottom: 1px rgba(255,255,255, 0.5) solid;
                        padding: 10px 2px;
                        vertical-align: middle;
                        text-align: right;
                        font-family: monospace;
                        font-size: 18px
                    }

                    & table {
                        border-spacing: 0;
                        border-collapse: collapse; 
                        width: 100%;
                        position: relative;
                    }
                    & th {
                        position: sticky;
                        top: 0; /* Don't forget this, required for the stickiness */
                        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                        text-align: right;
                        font-weight: bold;
                        font-size: 12px;
                        color: white;
                    }
                    & td:first-child {
                        text-align: left;
                    }
                    & th:first-child {
                        text-align: left;
                    }
    
                `}>
                    { roundData.map((data, index) => {
                        if (index > 0 ) return;
                        return (
                        <div>
                            <div css={css`margin-bottom: 30px; font-family: "amboy-black", sans-serif; font-size: 20px; letter-spacing: 1px; text-align: center; color: white;`}>
                                Round results
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>round</th>
                                        <th>{data.name}</th>
                                        <th>{roundData[1]?.name ?? '-'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {//@ts-ignore
                                    Object.entries(data.roundResult).map((round, index) => (
                                        <tr key={`round-${index}`}>
                                            <td>{round[0]}</td>
                                            <td>{round[1].avg}</td>
                                            <td>{roundData[1]?.name ? roundData[1].roundResult[round[0]].avg : "-"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    );})}
                        
                </div>
 
            
            
                <div css={css`
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-top: 30px;
                    & > div {
                        flex: 1 0 50%;
                        padding: 0 20px 30px;
                    }
                    
                    & td {
                        color: rgba(255,255,255, 0.6);
                        border-bottom: 1px rgba(255,255,255, 0.5) solid;
                        padding: 10px 2px;
                        vertical-align: middle;
                    }

                    & table {
                        border-spacing: 0;
                        border-collapse: collapse; 
                        width: 100%;
                        position: relative;
                    }
                    & th {
                        position: sticky;
                        top: 0; /* Don't forget this, required for the stickiness */
                        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
                        text-align: left;
                        font-weight: bold;
                        font-size: 12px;
                        color: white;
                    }
                    & td:last-child {
                        text-align: right;
                        font-family: monospace;
                        font-size: 18px
                    }

                    & th:last-child {
                        text-align: right;
                    }
    
                `}>
                    { tableData.map((data, index) => (
                        <div>
                            <div css={css`margin-bottom: 30px; font-family: "amboy-black", sans-serif; font-size: 20px; letter-spacing: 1px; text-align: center; color: white;`}>
                                {teams[index]?.name ?? 'All users'}
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>rank</th>
                                        <th>name</th>
                                        <th>total score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {//@ts-ignore
                                    data.map((user, index) => (
                                        <tr key={`user-${index}`}>
                                            <td>{index + 1}</td>
                                            <td>{user.name as unknown as string}</td>
                                            <td>{user.totalScore as unknown as string}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    
                </div>
            </Stage>
    );
}