import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { SPORT_TYPES_INPUT } from '../../constant';

export const TeamEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" required />
            <SelectInput source="type" choices={SPORT_TYPES_INPUT} required />
        </SimpleForm>
    </Edit>
);