/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { Stage } from '../core/Stage';
import { useSkid } from '../hooks/useSkid';

export const Introduction = ({onClick}: {onClick: () => void }) => {
    const { selectedTeam } = useSkid();
    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    return (
        <Stage gradiendDegree={-90} gradientColorStart={gt[0]} gradientColorEnd={gt[1]} bottom={<Button copy="Los geht's" onClick={onClick} gradiendDegree={189}/>} noTop>
            <div css={css`
                text-align: center;
                color: white;
                padding-bottom: 20px;
                font-weight: bold;
                font-size: 20px;
            `}>
                <div>
                    <svg
                        css={css`
                            fill: white;
                            width: 40px;
                            height: 40px;
                            margin-bottom: 10px;
                            opacity: 0.75;
                        `}
                        xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m515-80-10-110h-40q-143 0-244-101T120-535q0-143 101.5-244T467-880q71 0 131.5 25.5T704-783q45 46 70.5 108.5T800-538q0 66-19.5 132T724-280q-37 60-90 111T515-80Zm50-108q82-69 128.5-162.5T740-538q0-124-77.5-203T467-820q-120 0-203.5 83.5T180-535q0 118 83.5 201.5T465-250h100v62Zm-98-115q16 0 27-11t11-27q0-16-11-27t-27-11q-16 0-27 11t-11 27q0 16 11 27t27 11Zm-27-136h50q0-25 8.5-41.5T534-524q27-27 38-49.5t11-48.5q0-45-30.5-74T471-725q-42 0-75 22t-49 60l46 19q11-26 30.5-38.5T468-675q30 0 47 14.5t17 38.5q0 19-11 38.5T482-535q-27 28-34.5 45t-7.5 51Zm20-65Z"/></svg>
                    <div>
                        <div css={css`margin-bottom: 10px; font-size: 20px;`}>Lerne wie Du Einfluss nehmen kannst</div>
                    </div>
                </div>
            </div>
        </Stage>
    )       
}