/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Decision } from "../client/skid-worker";
import { useSkid } from "../hooks/useSkid";

export const DecisionSelector: React.FunctionComponent<{
  onChange: (value: string) => void
  decisions?: Decision[],
  winnerDecisionTypes?: Decision[]
}> = ({ onChange, decisions, winnerDecisionTypes }) => {
  const { decisionCopy } = useSkid()
  
  return (
    <select
      css={css`padding: 8px; font-size: 16px; background: rgba(255,255,255, 0.6); outline: 0; border-radius: 4px; max-width: 200px`}
      onChange={(e) => onChange(e.currentTarget.value)}
    >
      <option value="">Jetzt wählen</option>
      {(decisions ?? []).map((d) => {
          if ((winnerDecisionTypes ?? []).find((wd) => wd && wd.id === d.id) !== undefined) {
            return null;
          }
          return <option key={d.id} value={d.id} >{decisionCopy[d.type]?.name ?? d.type}</option>
      })}
    </select>
  );
}