import { Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { SPORT_TYPES_INPUT } from '../../constant';

export const DecisionEdit = () => (
    <Edit mutationMode='optimistic'>
        <SimpleForm>
            <TextInput source="name" disabled />
            <SelectInput source="sport" choices={SPORT_TYPES_INPUT} required />
            <TextInput source="description" multiline fullWidth/>
        </SimpleForm>
    </Edit>
);