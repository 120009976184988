import { useCallback, useEffect, useMemo, useRef, useState } from "react";

function getCopyByStageAndTime(stage: number, timeLeft: number, defaultTime: number): string | undefined {
   if (stage < 2) {
      return stage === 0 ? 'Ready' : 'Set';
   }

   if (timeLeft === defaultTime) {
      return 'GO!'
   }

   return undefined;
}

function calculateRestSeconds(date?: Date, buffer: number = 0): number {
   if (!date) {
      return 0
   }
   
   return Math.round((
      (date.getTime() + buffer) - (new Date()).getTime())
      / 1000
   );
}

export function useCompletedAtTimer(completedAt: Date | undefined, buffer: number = 0, disableCountdown: boolean = false, defaultActive: boolean = false) {
   const restSeconds = useMemo(() => calculateRestSeconds(completedAt, buffer), [completedAt, buffer]);

   const { startCountdown: timerStartCountdown, ...rest} = useCountdownTimer(restSeconds, disableCountdown, defaultActive)
   const startCountdown = useCallback(() => {
      timerStartCountdown(calculateRestSeconds(completedAt, buffer))
   }, [buffer, completedAt, timerStartCountdown])

   return {
      startCountdown,
      ...rest
   }
}

export function useCountdownTimer(defaultTime: number, disableCountdown: boolean = false, defaultActive: boolean = false) {
   const [isActive, setIsActive] = useState<boolean>(defaultActive && defaultTime > 0 )
   const [countdownStage, setCountdownStage] = useState<number>(0)
   const [timeLeft, setTimeLeft] = useState<number>(defaultTime * 10)
   let timeOutId = useRef<NodeJS.Timeout | undefined>(undefined)
   useEffect(() => {
      setTimeLeft(defaultTime * 10)
      clearTimeout(timeOutId.current)
      setIsActive(defaultActive && defaultTime > 0)
   }, [defaultActive, defaultTime])

   useEffect(() => {
      if (isActive) {
         if (timeLeft >= 1) {
            timeOutId.current = setTimeout(() => {
               if (countdownStage < 2 && !disableCountdown) {
                  setCountdownStage(countdownStage + 1)
               } else {
                  setTimeLeft(timeLeft - 1);
               }
            }, countdownStage < 2 && !disableCountdown ? 1000 : 100);
         }
      }
   }, [timeLeft, isActive, countdownStage, defaultTime, disableCountdown]);

   const startCountdown = useCallback((newTimeLeft?: number) => {
      newTimeLeft !== undefined && setTimeLeft(newTimeLeft * 10); 
      setIsActive(true)
   }, [setIsActive, setTimeLeft])

   const cancelCountdown = useCallback(() => setIsActive(false), [setIsActive])
   const resetCountdown = useCallback(() => {
      setIsActive(false)
      setTimeLeft(defaultTime * 10);
      setCountdownStage(0)
   }, [defaultTime])

   return {
      startCountdown,
      cancelCountdown,
      resetCountdown,
      display: isActive ? getCopyByStageAndTime(countdownStage, timeLeft, defaultTime) : undefined,
      isActive,
      isTimerActive:  timeLeft !== 0 && countdownStage > 1,
      hasFinished: timeLeft <= 0,
      timeLeft: Math.ceil(timeLeft / 10),
      timeLeftDisplay: `${Math.floor(timeLeft / 10)}.${timeLeft % 10}`,
   }
}