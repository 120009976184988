import { ArrayField, ChipField, Datagrid, EditButton, ListButton, Show, SimpleShowLayout, SingleFieldList, TabbedShowLayout, TextField, TopToolbar } from "react-admin";
import { EventNameField } from "../events";

const TeamShowActions = () =>(
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
)

export const TeamShow = () => (
    <Show queryOptions={{ meta: { includeEvents: true }}} actions={<TeamShowActions />}>
        <SimpleShowLayout>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="team">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="type" label="Sport" />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="events" path="events">
                <ArrayField source="events" label={false}>
                    <Datagrid bulkActionButtons={false}>
                        <EventNameField source="name" label="Event" />
                        <ArrayField source="games" >
                            <SingleFieldList linkType={false}>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </SimpleShowLayout>
    </Show>
);