import { Button, Link, useRecordContext } from "react-admin";
import { useParams } from "react-router-dom";

export const ShowSkidButton = () => {
    const record = useRecordContext();
    const param = useParams()

    return (
        <Button
            component={Link}
            to={`/events/${param.id}/games/${param.gameId}/skids/${record?.id ?? param.skidId}/show`}
            label="show"
        />
    );
};