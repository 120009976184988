/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Decision } from '../client/skid-worker';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';

export const DecisionsContent: React.FunctionComponent<{ gradientType: string[], winnerDecisionTypes?: Decision[] }> = ({ gradientType, winnerDecisionTypes}) => {
  const { selectedTeam, decisions, decisionCopy } = useSkid();
  const gt = gradientType ?? GradientTypes[selectedTeam?.gradientType ?? 'default']
  return (
      <div css={css`overflow-x: hidden; height: 430px;`}>
        <div css={css`
          text-align: left;
          background: ${gt[0]};
          background: linear-gradient(110deg, ${gt[0]} -30%, ${gt[1]} 130%);
          padding: 20px;
          margin: 0;
          min-height: 100%;
        `}>
          Pro Runde kann jedes Team eine der folgenden Action-Cards auswählen:
          <ul css={css`padding-left: 15px`}>
          {decisions?.map((d) => {
            const hasBeenWinning = (winnerDecisionTypes ?? []).find(wd => wd.id === d.id)
            return (
              <li key={d.id} css={css`margin-bottom: 10px`}>
                {hasBeenWinning && <span css={css`text-decoration: line-through `}>{decisionCopy[d.type]?.name ?? d.type}</span>}
                {!hasBeenWinning && <><strong>{decisionCopy[d.type]?.name ?? d.type}</strong><br /><span css={css`font-size: 14px`}>{decisionCopy[d.type]?.description ?? 'Keine Beschreibung'}</span></>}
              </li>
          )})}
          </ul>

        </div>
    </div>
  );
}