import { ArrayField, BooleanField, ChipField, Datagrid, NumberField, ReferenceManyField, Show, SimpleShowLayout, SingleFieldList, TextField } from "react-admin";
import { useParams } from "react-router-dom";
import { CreateSkidButton } from "../skids/CreateSkidButton";
import { EditSkidButton } from "../skids/EditSkidButton";
import { ShowSkidButton } from "../skids/ShowSkidButton";
import { EditGameButton } from "./EditGameButton";
import { GameActions } from "./GameActions";
import { GameTitle } from "./GameTitle";

export const GameShow = () => {
    const { id, gameId } = useParams();
    return (
        <Show
            resource="games"
            id={gameId}
            queryOptions={{ meta: { eventId: id }}}
            actions={<GameActions extra={[<EditGameButton />]} />}
            title={<GameTitle />}
        >
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="type" />
                <ReferenceManyField reference="teams" target="game_id" label="Teams">
                    <SingleFieldList linkType="show">
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceManyField>
                <ReferenceManyField reference="gameDecisions" target="game_id" label="Decisions">
                    <SingleFieldList linkType={false}>
                        <ChipField source="type" />
                    </SingleFieldList>
                </ReferenceManyField>
                <ArrayField source="skids" >
                    <Datagrid bulkActionButtons={false}>
                        <NumberField source="order_number" label="Nr" />
                        <TextField source="name" />
                        <TextField source="type" />
                        <BooleanField source="can_teams_select" label="Team Selection" />
                        <>
                            <ShowSkidButton />
                            <EditSkidButton />
                        </>
                    </Datagrid>
                </ArrayField>
                <CreateSkidButton />
            </SimpleShowLayout>
        </Show>
    )    
};