import { Edit } from 'react-admin';
import { useParams } from 'react-router-dom';
import { GameActions } from './GameActions';
import { GameEditForm } from './GameEditForm';
import { GameTitle } from './GameTitle';
import { ShowGameButton } from './ShowGameButton';

export const GameEdit = () => {
    const { id, gameId } = useParams()

    return (
        <Edit
            mutationMode='optimistic'
            resource='games'
            id={gameId}
            actions={<GameActions extra={[<ShowGameButton />]}/>}
            queryOptions={{ meta: { eventId: id }}}
            mutationOptions={{ meta: { eventId: id }}}
            redirect={(_resource: any, _id: any, data: any) => `events/${data.event_id}/games/${gameId}/show`}
            title={<GameTitle prefix='Edit' />}
        >
            <GameEditForm />
        </Edit>
    );
}