import { BooleanInput, Create, Loading, NumberInput, SelectInput, SimpleForm, TextInput, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { SKID_TYPES_INPUT } from '../../constant';
import { SkidActions } from './SkidActions';

export const SkidCreate = () => {
    const { id, gameId } = useParams()
    const { data, isLoading } = useGetOne('games', { id: gameId, meta: { eventId: id } }, { enabled: Boolean(id)});

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Create
            title={`Add skid round to game ${data?.name}`}
            resource="skids"
            mutationOptions={{ meta: { eventId: id, gameId }}}
            redirect={(_resource: any, _id: any, data: any) => `events/${data.event_id}/games/${data.game_id}/show`}
            actions={<SkidActions />}
        >
            <SimpleForm>
                <NumberInput source="order_number" label="Rundennummer" required />
                <TextInput source="name" required />
                <SelectInput source="type" choices={SKID_TYPES_INPUT} required />
                <BooleanInput source="can_teams_select" label="Can teams select decisions?" />
            </SimpleForm>
        </Create>
    );
}