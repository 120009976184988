import { DataProvider } from "react-admin";
import { createDecision, createEvent, createGame, createSkidForGame, createTeam, deleteDecision, deleteSkid, getDecisions, getDecisionsByGameId, getDecisionsById, getEventById, getEvents, getGameById, getGamesByEventId, getSkidsByGameId, getTeamById, getTeams, getTeamsByGameId, getUserById, getUsers, setDecisionsByGameId, setTeamsByGameId, updateDecision, updateEvent, updateGame, updateSkid, updateTeam, updateUser } from "../../client/skid-worker";

const eventDataProvider: Partial<DataProvider> = {
    getList: () => getEvents().then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getOne: (_, { id }) => getEventById(id as string).then(r => ({ data: r })),
    update: (_, { id, data }) => updateEvent(id, data.name).then((r: any) => ({ data: r })),
    create: (_, { data }) => createEvent(data as any).then((r: any) => ({ data: r })),
}

const gameDataProvider: Partial<DataProvider> = {
    getList: (_, params) =>  getGamesByEventId(params.meta.id as string).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getOne: async (_, params) => {
        const game = await getGameById(params.meta.eventId, params.id as string)
        const teams = await getTeamsByGameId(params.id as string)

        return {
            data: {
                ...game,
                teams: teams.map((t: any) => t.team_id)
            }
        }
    },
    getManyReference: (_, params) => getGamesByEventId(params.id as string).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    update: async (_, { id, data: { name, type, teams, gameDecisions }, meta }) => {
        const result = await updateGame(meta.eventId, id, { name, type }).then(r => ({ data: r }))
        await setTeamsByGameId(id, { ids: teams ?? [] })
        await setDecisionsByGameId(id, gameDecisions ?? [])
        return result;
    },
    create: async (_, { meta, data: {teams, gameDecisions, ...rest} }) => {
        const result = await createGame(meta.eventId, rest as any).then(r => ({ data: r }))
        if (result && teams && teams.length > 0) {
            await setTeamsByGameId(result.data.id, { ids: teams })
        }
        if (result && gameDecisions && gameDecisions.length > 0) {
            await setDecisionsByGameId(result.data.id, gameDecisions)
        }
        return result;
    },
}

const skidDataProvider: Partial<DataProvider> = {
    getList: async (_, params) => await getSkidsByGameId(params.meta.eventId, params.meta.gameId),
    getOne: async (_, params) => {
        const skids = await getSkidsByGameId(params.meta.eventId, params.meta.gameId) as { id: string }[] | null
        const skid = (skids ?? []).find((skid) => skid.id === params.id) as any

        return (skid ? { data: skid } : null) as any
    },
    update: (_, { id, data: { name, type, can_teams_select, order_number }, meta }) => updateSkid(meta.eventId, meta.gameId, id, { name, type, can_teams_select, order_number }).then(r => ({ data: r })),
    create: (_, { meta, data, }) => createSkidForGame(meta.eventId, meta.gameId, data as any).then(r => ({ data: r })),
    delete: (_, { id, meta, }) => deleteSkid(meta.eventId, meta.gameId, id as string).then(r => ({ data: r })),
}

const teamDataProvider: Partial<DataProvider> = {
    getList: (_, { pagination }) => getTeams({ limit: pagination.perPage, skip: (pagination.page - 1) * pagination.perPage}).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getOne: (_, params) => getTeamById(params.id as string, { includeEvents: params.meta?.includeEvents ?? false}).then(r => ({ data: r })),
    update: (_, { id, data: { name, type } }) => updateTeam(id, { name, type }).then(r => ({ data: r })),
    create: (_, { data }) => createTeam(data as any).then(r => ({ data: r })),
    getMany: (_, { ids }) =>  getTeams({ ids: ids as string[] }).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getManyReference: async (_, { id }) => {
        const teams = await getTeamsByGameId(id as string)
        return {
            data: teams.map((team: any) => ({
                id: team.team_id,
                ...team.teams
            })),
            total: teams.length,
        };
    }
}

const userDataProvider: Partial<DataProvider> = {
    getList: (_, { pagination }) =>  getUsers({ limit: pagination.perPage, skip: (pagination.page - 1) * pagination.perPage}).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getOne: (_, params) => getUserById(params.id as string).then(r => ({ data: r })),
    update: (_, { id, data: { nickname } }) => updateUser(id, { nickname }).then(r => ({ data: r })),
}

const decisionDataProvider: Partial<DataProvider> = {
    getList: async (_, { pagination, meta }) => await getDecisions( meta?.sport, { limit: pagination.perPage, skip: (pagination.page - 1) * pagination.perPage}).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    getOne: (_, params) => getDecisionsById(params.id as string).then(r => ({ data: r })),
    update: (_, { id, data: { sport, description } }) => updateDecision(id, { sport, description }).then(r => ({ data: r })),
    create: (_, { data, }) => createDecision(data as any).then(r => ({ data: r })),
    delete: (_, { id, }) => deleteDecision(id as string).then(r => ({ data: r })),
}

const gameDecisionsDataProvider: Partial<DataProvider> = {
    getList: (_, { meta }) => getDecisionsByGameId(meta?.gameId).then(r => ({ data: r, total: undefined, pageInfo: undefined })),
    updateMany: (_, { meta, data: { decisions } }) => setDecisionsByGameId(meta.gameId, decisions).then(r => ({ data: r })),
    getManyReference: (_, params) => getDecisionsByGameId(params.id as string).then(r => ({ data: r, total: undefined, pageInfo: undefined  }))
}

/** CONFIG */
const dataProviderMap: Record<string, Partial<DataProvider>> = {
    "events": eventDataProvider,
    "games": gameDataProvider,
    "teams": teamDataProvider,
    "users": userDataProvider,
    "skids": skidDataProvider,
    "decisions": decisionDataProvider,
    "gameDecisions": gameDecisionsDataProvider,
}

/** ABSTRACT */
const caller = async (method: string, resource: string, params: any) => {
    if (!dataProviderMap[resource]) {
        throw Error("resource not configured in data provider")
    }

    if (!dataProviderMap[resource][method]) {
        throw Error(`method [${method}] not configured for ${resource}`)
    }

    return dataProviderMap[resource][method](resource, params)
}

export const dataProvider: DataProvider = {
    getList: async (resource, params) => caller('getList', resource, params),
    getOne: async (resource, params) => caller('getOne', resource, params),
    getMany: async (resource, params) => caller('getMany', resource, params),
    getManyReference: async (resource, params) => caller('getManyReference', resource, params),
    create: async (resource, params) => caller('create', resource, params),
    update: async (resource, params) => caller('update', resource, params),
    updateMany: async (resource, params) => caller('updateMany', resource, params),
    delete: async (resource, params) => caller('delete', resource, params),
    deleteMany: async (resource, params) => caller('deleteMany', resource, params),
}