import axios, { AxiosResponse } from "axios";
import UAParser from "ua-parser-js";

type ListQueryParams = { ids?: string[], limit?: number, skip?: number}

const getListQueryString = (params?: ListQueryParams, additionalQueries?: Record<string, string>): string => {
  if (!params || !additionalQueries) {
    return ''
  }
  
  const query = new URLSearchParams()
  if (params?.ids) {
    query.append('ids', params.ids!.join(','))
  }

  if (params.limit !== undefined) {
    query.append('limit', `${params.limit}`)
  }

  if (params.skip !== undefined) {
    query.append('skip', `${params.skip}`)
  }

  if (additionalQueries) {
    for(const [key, value] of Object.entries(additionalQueries)) {
      query.append(key, value)
    }
  }

  const string = query.toString();
  return string ? `?${string}` : '' 
}

const client = axios.create({
    baseURL: window.location.host.includes('localhost') ? 'http://localhost:3000/local-worker' : 'https://skid-worker.superhard.workers.dev',
    timeout: 5000,
});

const handleResponse = async (request: Promise<AxiosResponse>) => {
    return request
        .then((response) => {
            if (response.data.success === true) {
                return response.data.data
            }
        })
        .catch()
}

/**
 * EVENTS
 */
export async function getEventById(eventId: string) {
    return handleResponse(client.get(`/events/${eventId}?include=games,skids`))
  }

export async function getEvents() {
  return handleResponse(client.get(`/events`))
}

export async function updateEvent(id: string, name: string) {
  return handleResponse(client.put(`/events/${id}`, { name }))
}

export async function createEvent(data: { name: string }) {
  return handleResponse(client.post(`/events`, data))
}


/**
 * Games
 */
export async function getGamesByEventId(eventId: string) {
  return handleResponse(client.get(`/events/${eventId}/games`))
}

export async function getGameById(eventId: string, gameId: string) {
  return handleResponse(client.get(`/events/${eventId}/games/${gameId}`))
}

export async function updateGame(eventId: string, gameId: string, data: { name: string, type: string }) {
  return handleResponse(client.put(`/events/${eventId}/games/${gameId}`, data))
}

export async function createGame(eventId: string, data: { name: string, type: string }) {
  return handleResponse(client.post(`/events/${eventId}/games`, data))
}

/**
 * SKIDS
 */
export function getSkidsByGameId(eventId: string, gameId: string) {
  return handleResponse(client.get(`/events/${eventId}/games/${gameId}/skids`))
}

export function createSkidForGame(eventId: string, gameId: string,  data: { order_number: number, name: string, type: string, can_teams_select: boolean }) {
  return handleResponse(client.post(`/events/${eventId}/games/${gameId}/skids`, data))
}

export function updateSkid(eventId: string, gameId: string, skidId: string, data: { order_number: number, name: string, type: string, can_teams_select: boolean }) {
  return handleResponse(client.put(`/events/${eventId}/games/${gameId}/skids/${skidId}`, data))
}

export function deleteSkid(eventId: string, gameId: string, skidId: string) {
  return handleResponse(client.delete(`/events/${eventId}/games/${gameId}/skids/${skidId}`))
}


/**
 * TEAMS
 */
export async function getTeams(options?: ListQueryParams) {
  return handleResponse(client.get(`/teams${getListQueryString(options)}`))
}

export async function getTeamById(teamId: string, options?: Partial<{ includeEvents: true }>) {
  return handleResponse(client.get(`/teams/${teamId}${options?.includeEvents ? '?include=events' : ''}`))
}

export async function updateTeam(teamId: string, data: { name: string, type: string }) {
  return handleResponse(client.put(`/teams/${teamId}`, data))
}

export async function createTeam(data: { name: string }) {
  return handleResponse(client.post(`/teams`, data))
}

export async function getTeamsByGameId(gameId: string) {
    return handleResponse(client.get(`/games/${gameId}/teams`))
}

export async function setTeamsByGameId(gameId: string, data: { ids: string[] }) {
  return handleResponse(client.post(`/games/${gameId}/teams`, data))
}

/**
 * Decisions
 */
export async function getDecisions(sport?: string, options?: ListQueryParams) {
  return handleResponse(client.get(`/decisions${getListQueryString(options, sport ? { sport } : undefined)}`))
}

export function getDecisionsById(decisionId: string) {
  return handleResponse(client.get(`/decisions/${decisionId}`))
}

export function createDecision(data: { name: string, sport: string, description: string }) {
  return handleResponse(client.post(`/decisions`, data))
}

export function updateDecision(decisionId: string, data: { sport: string, description: string }) {
  return handleResponse(client.put(`/decisions/${decisionId}`, data))
}

export function deleteDecision(decisionId: string) {
  return handleResponse(client.delete(`/decisions/${decisionId}`))
}

export type Decision = {
  "id": string
  "type": string,
  "game_id": string,
  "created_at": string,
  "updated_at": string,
  is_selectable: boolean,
}
export function getDecisionsByGameId(gameId?: string) {
  if (!gameId) {
    throw new Error('Cannot fetch game decision, no game id')
  }
  return handleResponse(client.get(`games/${gameId}/decisions`))
}

export function setDecisionsByGameId(gameId: string, decisions: string[]) {
  return handleResponse(client.put(`games/${gameId}/decisions`, { decisions }))
}


/**
 * USER
 */
export type User = {
    id: string,
    nickname: string,
    created_at: string,
    updated_at: string
}

export async function createUser(nickname: string) {
  return handleResponse(client.post(`users`, { nickname }))
}

export async function updateUser(userId: string, data: { nickname: string }) {
  return handleResponse(client.put(`users/${userId}`, data))
}

export async function getUsers(options?: ListQueryParams) {
    return handleResponse(client.get(`users${getListQueryString(options)}`))
}

export async function getUserById(userId: string) {
  return handleResponse(client.get(`users/${userId}`))
}

export async function selectGameTeam(data: { userId: string, gameId: string, teamId: string }): Promise<User> {
  return handleResponse(client.post(`users/${data.userId}/games/${data.gameId}/teams`, { team_id: data.teamId }))
}

/**
  * Game State
  */
export type GameState = {
  activeSkid?: {
    id: string,
    stage: 'user' | 'team'
    completedAt?: string
  }
  // teamId: { skidId: decisionId }
  decisionSelections?: Record<string, Record<string, string>>
  isComplete: boolean
}
export async function getGameState(gameId: string): Promise<GameState> {
    return handleResponse(client.get(`games/${gameId}/state`))
}

export async function updateGameState(gameId: string, skidId: string, stage: 'user' | 'team', secondsAdd = 40): Promise<GameState> {
  const data: { id: string, stage: string, completed_at?: string} = {
    id: skidId,
    stage,
  }
  if (stage === 'user') {
    const d = new Date()
    d.setSeconds(d.getSeconds() + secondsAdd);
    data.completed_at = d.toISOString()
  }
  return handleResponse(client.put(`games/${gameId}/state/active-skid`, data))
}

export type TeamDecision = {
  "id": string
  "team_id": string
  "decision_id": string,
  "skid_id": string,
  "game_id": string,
  "created_at": string,
  "updated_at": string,
}
export async function saveTeamDecisionState(gameId: string, skidId: string, decisionId: string, teamId: string): Promise<TeamDecision> {
  const data: { decision_id: string, skid_id: string } = {
    decision_id: decisionId,
    skid_id: skidId,
  }
  
  return handleResponse(client.put(`games/${gameId}/teams/${teamId}/decisions`, data))
}

// SKID
export type SkidType = 'swipe' | 'two' | 'three';
const ua = new UAParser(window.navigator.userAgent).getResult();
const user_agent = JSON.stringify(ua) //`${ua.browser.name}|${ua.device}|${ua.engine}|${ua.os}`
export async function createTestSkid(score: number, skid_type: SkidType, user_id: string): Promise<User> {
  return handleResponse(client.post(`skids/trainings/results`, { 
    score,
    skid_type,
    user_id,
    user_agent
   }))
}

export async function createSkid(eventId: string, gameId: string, skidId: string, data: { user_id: string, score: number, team_id: string }): Promise<User> {
  return handleResponse(client.post(`/events/${eventId}/games/${gameId}/skids/${skidId}/results`, {...data, user_agent}))
}

export type SkidResult = {
  score_int: number,
  user_id: string,
  team_id: string,
  game_id: string
  event_id: string,
  skid_id: string
}
export async function getSkidResults(eventId: string, gameId: string): Promise<SkidResult[]> {
  return handleResponse(client.get(`/events/${eventId}/games/${gameId}/results`))
}