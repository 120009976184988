/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSkid } from '../hooks/useSkid';
import { useSkidDecisions } from '../hooks/useSkidDecision';

export const SkidResultLossWin: React.FunctionComponent<{
  ownTeamResult: number,
  otherTeamResult: number,
  skidId?: string,
}> = ({ ownTeamResult, otherTeamResult, skidId }) => {
    const { decisionCopy } = useSkid()
    const { data } = useSkidDecisions()
    const outcome = ownTeamResult === otherTeamResult ? 'DRAW' : (ownTeamResult < otherTeamResult ? "LOSS" : "WIN")
    const decisionType = data[skidId ?? '']?.type 
    const decisionName = decisionCopy[decisionType ?? '']?.name ?? decisionType
    return (
        <div css={css`
            text-align: center;
            color: white;
            padding-bottom: 20px;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 10px;
        `}>
            <div css={css` font-family: "amboy-black", sans-serif; font-size: 60px; letter-spacing: 5px; padding-bottom: 10px`}>
                {outcome} !
            </div>
            {outcome === "WIN" && (
                <div css={css`font-weight: normal; font-size: 16px; opacity: 0.8`}>
                    {decisionType === 'coin-toss' ? (<>Ihr habt den Coin Toss gewonnen!</>) : (
                        <>Ihr habt Euch <strong>"<u>{decisionName ?? 'no-selection'}</u>"</strong> erspielt!</>
                    )}
                </div>
            )}
            {outcome === "LOSS" && (
                <div css={css`font-weight: normal; font-size: 16px; opacity: 0.8`}>
                    {decisionType === 'coin-toss' ? (<>Ihr habt den Coin Toss verloren!</>) : (
                        <>Der Gegner hat sich <strong>"<u>{decisionName ?? 'no-selection'}</u>"</strong> erspielt!</>
                    )}
                </div>
            )}
        </div>
    );
}