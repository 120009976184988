
import { Datagrid, DateField, List, SimpleList, TextField } from 'react-admin';

export const UserList = () => {
    //@ts-ignore
    const isSmall = false
    return (
        <List perPage={4}>
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => record.name}
                />
            ) : (
                <Datagrid rowClick="show" bulkActionButtons={false}>
                    <TextField source="nickname" />
                    <DateField source="created_at" showTime={true} />
                </Datagrid>
            )}
        </List>
    );
}
