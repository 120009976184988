import { createContext, useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocalStorage } from "usehooks-ts";
import { Decision, getDecisions } from "../client/skid-worker";
import { colorPallete } from "../constants";
import { Game } from "../hooks/useEvent";
import { Team } from "../hooks/useGame";
import { useSelectTeam } from "../hooks/useSelectTeam";
import { useUser } from "../hooks/useUser";

export type User = {
  id?: string
  name: string
}

export type SkidStore = {
  hasCompletedWamrUp: boolean
  events: Record<string, {
    user?: User
    games: Record<string, {
      team?: Team
    }>
  }>
}

export const GradientTypes: Record<string, string[]> = {
  'team-1': [colorPallete['green'], colorPallete['purple']],
  'team-2': [colorPallete['yellow'], colorPallete['purple']],
  'default': [colorPallete['purple'], colorPallete['blue']],
}

export type SkidContextType = {
  eventId: string
  teams: Team[]
  game: Game
  decisions: Decision[] | undefined
  decisionCopy: Record<string, {name: string, description?: string}>
  
  currentUser?: User
  selectedTeam?: Team
  hasCompletedWarmUp: boolean

  selectTeam: (id: string) => Promise<boolean | string>
  createUser: (name: string) => Promise<boolean | string>
  completeWarmup: () => void
  
  skidZoneSize: 'small' | 'medium' | 'xl' | '2xl' | '3xl',
  isLoading: boolean,
  isInitialLoading: boolean,
  isCreatingUser: boolean,
  isSavingTeamSelection: boolean,
  isSkidSaving: boolean,
}

export const SkidContext = createContext<SkidContextType>({
  createUser: async (_id: string) => { return 'Not implemented'; },
  selectTeam: async (_id: string) => { return 'Not implemented'; },
  teams: [],
  decisions: undefined,
  hasCompletedWarmUp: false,
  completeWarmup: () => { return; },
  skidZoneSize: 'medium',
  isLoading: false,
  isInitialLoading: false,
  isCreatingUser: false,
  isSavingTeamSelection: false,
  isSkidSaving: false,
  game: {} as Game,
  eventId: '',
  decisionCopy: {},
});

export const SkidProvider = ({ children, game, gameId, eventId, teams, decisions }: { children?: React.ReactNode, game: Game, gameId: string, eventId: string, teams?: Team[], decisions?: Decision[]}) => {
  const { create: persistuser, isSavingUser } = useUser();
  const { selectTeam: selectGameTeam, isSavingTeaamSelection } = useSelectTeam();
  
  const [skidStore, saveSkidStore] = useLocalStorage<SkidStore>("skid-store-v2", {
    hasCompletedWamrUp: false,
    events: {}
  });


  const { data: decisionCopyData } = useQuery(['decisionDescription', game.type], async () => await getDecisions(game.type) as {name: string, description: string}[], {})

  const decisionCopy = useMemo(() => {
      const copyByType: Record<string, { name: string, description?: string}> = {}
      if (decisionCopyData && decisionCopyData.length > 0) {
        for (const d of decisionCopyData) {
          copyByType[d.name] = { name: d.name, description: d.description}
        }
      }

      return copyByType;
  }, [decisionCopyData])
  
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(skidStore.events[eventId!]?.games[gameId!]?.team);
  const [currentUser, setSelectedUser] = useState<User | undefined>(skidStore.events[eventId!]?.user);
  const [hasCompletedWarmUp, setHasCompletedWarmUp] = useState<boolean>(skidStore.hasCompletedWamrUp);

  const selectTeam = useCallback(async (id: string) => {
    const result = await selectGameTeam({
      teamId: id,
      userId: currentUser!.id as string,
      gameId: gameId!
    })

    if (typeof result === "string") {
      return result
    }

    const team = (teams ?? []).find((team) => team.id === id);
    saveSkidStore((prevState) => {
      const newState = {...prevState};
      if (!newState.events[eventId!]) {
        newState.events[eventId!] = {
          games: {}
        }
      }

      if (!newState.events[eventId!].games) {
        newState.events[eventId!].games = {}
      }
      if (!newState.events[eventId!].games[gameId!]) {
        newState.events[eventId!].games[gameId!] = { team }
      }

      return newState;
    } );
    setSelectedTeam(team)

    return true
  }, [selectGameTeam, currentUser, gameId, teams, saveSkidStore, eventId])

  const createUser = useCallback(async (nickname: string) => {
    const user: User = { name: nickname };
    const result = await persistuser({ nickname })
    if (typeof result === 'string') {
      return result
    }

    user.id = result.id;

    setSelectedUser(user)
    saveSkidStore((prevState) => {
      const newState = {...prevState};
      if (!newState.events[eventId!]) {
        newState.events[eventId!] = {
          games: {}
        }
      }

      newState.events[eventId!].user = user;

      return newState;
    });

    return true;
  }, [persistuser, eventId, saveSkidStore])

  const completeWarmup = useCallback(() => {
    saveSkidStore({...skidStore, hasCompletedWamrUp: true});
    setHasCompletedWarmUp(true)
  }, [saveSkidStore, skidStore])

  //@ts-ignore
  return <SkidContext.Provider value={{
    // data
    eventId,
    game,
    teams: teams ?? [],
    decisions,
    selectedTeam,
    currentUser,
    hasCompletedWarmUp,
    decisionCopy,
    
    // fns
    selectTeam,
    createUser,
    completeWarmup,
    
    // status vars
    skidZoneSize: 'medium',
    isCreatingUser: isSavingUser,
    isSavingTeamSelection: isSavingTeaamSelection,
    
  }}>{children}</SkidContext.Provider>;
}
