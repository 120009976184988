import { EditButton, ListButton, Show, SimpleShowLayout, TextField, TopToolbar } from "react-admin";

const UserShowActions = () =>(
    <TopToolbar>
        <ListButton />
        <EditButton />
    </TopToolbar>
)

export const UserShow = () => (
    <Show actions={<UserShowActions />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="nickname" />
        </SimpleShowLayout>
    </Show>
);