/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { Stage } from '../core/Stage';
import { useSkid } from '../hooks/useSkid';

export const NoDecision = () => {
    const { selectedTeam, isInitialLoading } = useSkid();
    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    return (
        <Stage isLoading={isInitialLoading} gradiendDegree={0} gradientColorStart={gt[0]} gradientColorEnd={gt[1]}>
            <div css={css`
                text-align: center;
                color: white;
                padding-bottom: 20px;
                font-weight: bold;
                font-size: 20px;
            `}>
                <div>
                    <svg
                        css={css`
                            fill: white;
                            width: 40px;
                            height: 40px;
                            margin-bottom: 10px;
                            opacity: 0.75;
                        `}
                        xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m381-218-43-43 100-99-100-99 43-43 99 100 99-100 43 43-100 99 100 99-43 43-99-100-99 100ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Z"/></svg>
                    <div>
                        <div css={css`margin-bottom: 10px`}>Uups, nix los hier!</div>
                        <span
                            css={css`
                                font-weight: normal;
                                font-size: 17px;
                            `}
                        >
                            Aktuell gibt es keine anstehende Entscheidung. Komm später noch mal vorbei.
                        </span>
                    </div>
                </div>
            </div>
        </Stage>
    )       
}