/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type SkidHeaderProps = {
  children: React.ReactNode
}

export const SkidHeader: React.FunctionComponent<SkidHeaderProps> = ({ children }) => {
  return (
    <div
        css={css`
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 0.8);
            height: 100px;
            border-top: 2px solid white;
            padding: 10px 0;
        `}
    >
        {children}
    </div>
  );
}