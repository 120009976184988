import { useEffect } from "react";
import { useQuery } from "react-query";
import { getEventById, getEvents } from "../client/skid-worker";
import { useWindowFocus } from "./useWindowFocus";

export type ResultTeam = Record<string, { rounds: Record<string, number[]>, name: string, id: string, userIds: string[], roundResult: Record<string, { avg: number, sum: number, count: number}>}>
export type ResultUser =  Record<string, { rounds: Record<string, number[]>, name: string, id: string, teamId: string, totalScore: number}>
export type Result = {
  teams: ResultTeam
  users: ResultUser
}

export type Event = {
  created_at: string
  id: string
  name: string
  updated_at: string
  games?: Game[]
}

export type Skid = {
  activated_at: string | null
  created_at: string
  event_id: string
  game_id: string
  id: string
  name: string
  order_number: number
  type: string
  updated_at: string
  can_teams_select: boolean
}

export type Game = {
  completed_at: string | null
  created_at: string
  event_id: string
  id: string
  name: string
  type: string
  updated_at: string
  skids?: Skid[]
}

export function useEvent(eventId?: string) {
  const hasFocus = useWindowFocus()
  const { isLoading, isFetching, data, refetch } = useQuery(['events', eventId], async () => await getEventById(eventId as string), {
    enabled: Boolean(eventId)
  })

  // update state on window focus
  useEffect(() => {
    if (hasFocus) {
      refetch()
    }
  }, [refetch, hasFocus])

  return {
    isInitialLoading: isLoading,
    isLoading: isFetching,
    event: data as Event
  }
}

export function useEvents() {
  const { isLoading, isFetching, data } = useQuery(['events', 'all'], async () => await getEvents())

  return {
    isInitialLoading: isLoading,
    isLoading: isFetching,
    events: data as Event[]
  }
}