/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Stage } from "../core/Stage";

export const Events = () => {
    return (
        <Stage isLoading={false} noTop>
            <div css={css`
                margin-bottom: 20px;
                text-align: center;
                color: white;
                font-weight: bold;
            `}>
                Bitte browse direkt über die Event-Url um teilzunehmen.
            </div>
        </Stage>
    );
}