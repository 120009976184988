import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { SPORT_TYPES_INPUT } from '../../constant';

export const DecisionCreate = () => (
    <Create redirect="list">
        <SimpleForm>
            <TextInput source="name" required/>
            <SelectInput source="sport" choices={SPORT_TYPES_INPUT} required />
            <TextInput source="description" multiline fullWidth/>
        </SimpleForm>
    </Create>
);