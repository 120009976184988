import { useMemo } from "react";
import { useQuery } from "react-query";
import { getGameState } from "../client/skid-worker";
import { Game } from "./useEvent";

export function useGameState(game: Game) {
  const { isLoading: isInitialLoadingGameTeams, isFetching: isLoadingGameState, data, refetch } = useQuery(
    {
      queryKey: ['gameState', game.id],
      queryFn: async () => await getGameState(game.id),
      refetchInterval: 3000
    })
  const state = useMemo(() => {
    if (isInitialLoadingGameTeams || (game?.skids ?? []).length === 0 || !data?.activeSkid) {
      return null
    }

    // get skid by game state
    const skids = (game.skids ?? []).sort((a, b) => {
      return a.order_number < b.order_number ? -1 : 1;
    })

    let skidIndex = data.activeSkid ? skids.findIndex((s) => s.id === data!.activeSkid!.id) : 0
    if (!skids[skidIndex]) {
      return null
    }

    const skid = skids[skidIndex]
    const nextSkid = skids[skidIndex + 1] ?? null

    if (data.isComplete) {
      return {
        isComplete: true,
        decisionSelections: data.decisionSelections
      }
    }

    const completedAt = data.activeSkid.completedAt ? new Date(data.activeSkid.completedAt) : undefined;

    return {
      skid,
      nextSkid,
      completedAt,
      isRoundOver: completedAt ? completedAt.getTime() < (new Date()).getTime() : false,
      stage: data?.activeSkid?.stage,
      decisionSelections: data.decisionSelections
    }
  }, [isInitialLoadingGameTeams, game?.skids, data])
  
  return {
    isInitialLoading: isInitialLoadingGameTeams,
    isLoading: isLoadingGameState,
    state,
    refetch
  }
}