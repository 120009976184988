
import { Datagrid, DateField, List, SimpleList, TextField } from 'react-admin';

export const EventList = () => {
    //@ts-ignore
    const isSmall = false
    return (
        <List>
            {isSmall ? (
                <SimpleList
                    primaryText={(record) => record.name}
                />
            ) : (
                <Datagrid rowClick="show" bulkActionButtons={false} >
                    <TextField source="name" />
                    <DateField source="updated_at" showTime={true} />
                </Datagrid>
            )}
            
        </List>
    );
}
