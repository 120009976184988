/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';
export type ButtonProps = {
  copy: string;
  gradiendDegree?: number;
  gradientType?: string
  onClick?: () => void
  size?: 'small',
  isLoading?: boolean
}
export const Button: React.FunctionComponent<ButtonProps> = ({ copy, gradiendDegree, gradientType, onClick, size, isLoading }) => {
  const { selectedTeam } = useSkid()
  const isSmall = size === 'small'
  const gt = GradientTypes[gradientType ?? (selectedTeam?.gradientType ?? 'default')]
  return (
    <>
      <button
          onClick={onClick}
          disabled={isLoading}
          css={css`
              background: rgb(239,56,182);
              background: linear-gradient(${gradiendDegree ?? 220}deg, ${gt[0]} 0%, ${gt[1]} 100%);
              border-radius: ${isSmall ? 10 : 35}px;
              border: 1px solid white;
              color: white;
              height: ${isSmall ? 40 : 70}px;
              width: ${isSmall ? '100%' : '300px'};
              font-size: ${isSmall ? 12 : 24}px;
              font-weight: bold;
              &:hover {
                background: linear-gradient(${gradiendDegree ?? 220}deg, ${gt[0]} 30%, ${gt[1]} 100%);
              }
          `}>
        {!isLoading ? copy : (
        <span css={css`
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 3px solid rgba(255,255,255,.3);
          border-radius: 50%;
          border-top-color: #fff;
          animation: spin 1s ease-in-out infinite;
          -webkit-animation: spin 1s ease-in-out infinite;
        `} />)}
      </button>
    </>
  );
}