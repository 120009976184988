/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { Button, ButtonProps } from './Button';

export const DoubleOptInButton: React.FunctionComponent<ButtonProps> = ({ onClick, ...rest }) => {
  const [needsConfirmation, setNeedsConfirmation] = useState<boolean>(false)
  return needsConfirmation ? (
      <div css={css`display: flex; gap: 5px`}>
        <button css={css`background: red; border: none; outline: 0; color: white; border-radius: 3px;padding: 5px;`} onClick={() => setNeedsConfirmation(false)}>Cancel</button>
        <button css={css`background: green; border: none; outline: 0; color: white; border-radius: 3px;padding: 5px;`} onClick={onClick}>Confirm</button>
      </div>
    ): (
      <Button onClick={() => setNeedsConfirmation(true)} {...rest} />
    )
}