import { useMemo } from "react";
import { useGameState } from "./useGameState";
import { useSkid } from "./useSkid";

export function useTeamDecisionSelection (skidId?: string) {
    const { game, decisions, selectedTeam } = useSkid()
    const { state } = useGameState(game)
    const { decisionSelections, skid } = state ?? {}
    const teamSelectionForRound = useMemo(() => {
      if (
          decisionSelections
          && decisionSelections[selectedTeam?.id ?? '']
          && decisionSelections[selectedTeam!.id][skidId ?? (skid?.id ?? '')]
      ) {
          return decisions?.find(d => d.id === decisionSelections[selectedTeam!.id][skidId ?? (skid?.id ?? '')])
      }
    }, [decisionSelections, selectedTeam, skid, decisions, skidId])

    return teamSelectionForRound
}