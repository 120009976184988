import Add from '@mui/icons-material/Add';
import { Button, useRecordContext } from "react-admin";
import { Link, useParams } from "react-router-dom";

export const CreateSkidButton = () => {
    const record = useRecordContext();
    const param = useParams()

    return (
        <Button
            component={Link}
            to={`/events/${param.id}/games/${record.id}/skids`}
            label="Add skid"
            startIcon={<Add />}
        />
    );
};