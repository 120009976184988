import { useMutation, useQuery } from "react-query";
import { User, createUser, getUsers } from "../client/skid-worker";

export function useUser() {
  const { mutateAsync: create, isLoading: isSavingUser } = useMutation({
      //@ts-ignore
      mutationFn: async (data: { nickname: string }): Promise<User | string> => {
        const result = await createUser(data.nickname).catch((error) => {
          const response = error.response
          if (response.data && error.response.data.reason) {
            if (error.response.data.reason.startsWith('duplicate key value violates unique constraint')) {
                return 'Dieser Nickname ist schon vergeben'
            }

            return  error.response.data.reason
          }
          return 'Unknown error occcurred.';
        })

        return result
      }
    });

    const { isLoading: isInitialLoadingUsers, isFetching: isLoadingUsers, data } = useQuery(['users'], async () => await getUsers());

  return {
    create,
    isSavingUser,
    users: data ?? [],
    isInitialLoadingUsers,
    isLoadingUsers
  }
}