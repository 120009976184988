import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { DecisionCreate, DecisionEdit, DecisionList, DecisionShow } from './domain/decisions';
import { EventCreate, EventEdit, EventList, EventShow } from './domain/events';
import { GameEdit, GameShow } from './domain/games';
import { GameCreate } from './domain/games/GameCreate';
import { SkidCreate, SkidEdit, SkidShow } from './domain/skids';
import { TeamCreate, TeamEdit, TeamList, TeamShow } from './domain/teams';
import { UserEdit, UserList, UserShow } from './domain/users';
import { dataProvider } from './provider';

export const Root = () => (
    <Admin dataProvider={dataProvider} basename="/admin">
        <Resource
            name="events"
            list={EventList}
            show={EventShow}
            edit={EventEdit}
            create={EventCreate}
            icon={EmojiEventsOutlinedIcon}
            recordRepresentation={(record) => record.name}
        >
            <Route path=":id/games/:gameId/show" element={<GameShow />} />
            <Route path=":id/games/:gameId/edit" element={<GameEdit />} />
            <Route path=":id/games" element={<GameCreate />} />

            <Route path=":id/games/:gameId/skids/:skidId/show" element={<SkidShow />} />
            <Route path=":id/games/:gameId/skids/:skidId" element={<SkidEdit />} />
            <Route path=":id/games/:gameId/skids" element={<SkidCreate />} />
        </Resource>
        <Resource
            name="teams"
            recordRepresentation={(record) => record.name}
            list={TeamList}
            show={TeamShow}
            edit={TeamEdit}
            create={TeamCreate}
            icon={Groups2OutlinedIcon}
        />
        <Resource
            name="users"
            recordRepresentation={(record) => record.nickname}
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            icon={PersonOutlineOutlinedIcon}
        />
        <Resource
            name="decisions"
            recordRepresentation={(record) => record.name}
            list={DecisionList}
            show={DecisionShow}
            edit={DecisionEdit}
            create={DecisionCreate}
            icon={RuleIcon}
        />
    </Admin>
);