import { LinearProgress, SelectArrayInput, useGetList } from "react-admin";

interface GameDecisionSelectorFieldProps {
    sport: string,
    gameId?: string,
}

export const GameDecisionSelectorField = ({ sport, gameId }: GameDecisionSelectorFieldProps) => {
    const { data: availableDecisions, isLoading: isLoadingDecisions } = useGetList("decisions", { meta: { sport }})
    const { data: gameDecisions, isLoading: isLoadingGameDec } = useGetList("gameDecisions", { meta: { gameId }}, {enabled: !!gameId} )

    if (isLoadingDecisions || isLoadingGameDec) {
        return <LinearProgress />
    }
    
    return (
        <SelectArrayInput
            source="gameDecisions"
            choices={(availableDecisions ?? []).map(d => ({ id: d.name, name: d.name}))}
            defaultValue={(gameDecisions ?? []).map(d => d.type)}
        />
    );
};