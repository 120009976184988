/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { useSkid } from '../hooks/useSkid';
import { CircleIcon } from './CircleIcon';

export type SkidZoneSize = 'small' | 'medium' | 'xl' | '2xl' | '3xl';

type SkidProps = {
  isActive: boolean
  onSkid: () => void
  skidZoneSize?: SkidZoneSize,
  countdownCopy?: string,
}

const order = ['topLeft', 'topRight', 'bottomRight', 'bottomLeft']

export const SkidFour: React.FunctionComponent<SkidProps> = ({ isActive, onSkid, skidZoneSize, countdownCopy }) => {
  const { selectedTeam } = useSkid()
  const [ currentBoxId, setCurrentFieldId ] = useState<string | undefined>(undefined)
  const topLeftField = useRef<HTMLDivElement>(null)
  const topRightField = useRef<HTMLDivElement>(null)
  const bottomLeftField = useRef<HTMLDivElement>(null)
  const bottomRightField = useRef<HTMLDivElement>(null)

  useEffect(() => setCurrentFieldId(undefined), [isActive])

  const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']

  function getFieldIdByPage(page: React.Touch) {
    const topLeftBoundingRect = topLeftField.current!.getBoundingClientRect();
    const topRightBoundingRect = topRightField.current!.getBoundingClientRect();
    const bottomLeftBoundingRect = bottomLeftField.current!.getBoundingClientRect();
    const bottomRightBoundingRect = bottomRightField.current!.getBoundingClientRect();
    return  [
      {rect: topLeftBoundingRect, name: 'topLeft'},
      {rect: topRightBoundingRect, name: 'topRight'},
      {rect: bottomRightBoundingRect, name: 'bottomRight'},
      {rect: bottomLeftBoundingRect, name: 'bottomLeft'},
      ].reduce<string | undefined>((c, { rect, name }) => {
        if (c !== undefined) {
          return c
        }

        if (
          rect.left <= page.pageX &&  page.pageX <= rect.right
          && rect.top <= page.pageY && page.pageY <= rect.bottom
        ) {
          return name
        }

        return c;
    }, undefined)
  }

  function canSkidToNextBox(currentBoxId: string | undefined, nextBoxId: string | undefined | null) {
    if (!currentBoxId || !nextBoxId || currentBoxId === nextBoxId) {
      return false
    }
    const currentIndex = order.findIndex((e) => e === currentBoxId)
    const next = (currentIndex + 1) === order.length ? 0 : currentIndex + 1

    return nextBoxId === order[next]
  }

  const handleMouseEnter = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isActive && canSkidToNextBox(currentBoxId, event.currentTarget.getAttribute('data-box-id'))) {
      onSkid()
      setCurrentFieldId(event.currentTarget.getAttribute('data-box-id') ?? undefined)
    }
  }, [isActive, currentBoxId, setCurrentFieldId, onSkid])

  function handleTouchStart(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    setCurrentFieldId(getFieldIdByPage(event.touches[0]));
  }

  function handleTouchMove(event: React.TouchEvent<HTMLDivElement>) {
    event.stopPropagation();
    const touchBoxId = getFieldIdByPage(event.touches[0])
    if (event.touches.length > 0 && isActive) {
        if (currentBoxId === undefined && touchBoxId) {
          setCurrentFieldId(touchBoxId)
          return;
        }
      
        if (canSkidToNextBox(currentBoxId, touchBoxId)) {
          onSkid()
          setCurrentFieldId(touchBoxId)
        }
    }
  }

  let verticalDistance = 4;
  let horizontalDistance = 11;
  if (skidZoneSize === 'medium') {
    verticalDistance = 3
    horizontalDistance = 10
  }

  if (skidZoneSize === 'xl') {
    verticalDistance = 2
    horizontalDistance = 8
  }

  if (skidZoneSize === '2xl') {
    verticalDistance = 1
    horizontalDistance = 4
  }

  if (skidZoneSize === '3xl') {
    verticalDistance = 0
    horizontalDistance = 0
  }
  
  return (
    <div
        onTouchMove={handleTouchMove}
        css={css`
            opacity: ${isActive ? '1' : '0.8'};
            height: 60vw;
            position: relative;
            user-select: none;
        `}>
          {(!isActive && Boolean(countdownCopy)) && (<div css={css`opacity: 0.4; width: 50px; height: 50px; position: absolute; left: 50%; top: 50%; margin: -25px 0 0 -25px; z-index: 1000`}><CircleIcon /></div>)}
          <div
            ref={topLeftField}
            data-box-id="topLeft"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              background: ${gt[0]};
              opacity: ${isActive && currentBoxId === 'topLeft' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'topLeft' ? `0 0 10px -5px white;` : "none"}; 
              height: 45%;
              width: 38%;
              position: absolute;
              top: ${verticalDistance}%; left:${horizontalDistance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
            
          ></div>
          {/* <div css={css`
            width: ${skidZonwWidth}px;
            background-color: #ffffff;
            opacity: 0.1;
            background: repeating-linear-gradient( 45deg, #000000, #000000 2px, #ffffff 2px, #ffffff 10px );
          `}/> */}
          <div
            ref={topRightField}
            data-box-id="topRight"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'topRight' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId !== 'topRight' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
              height: 45%;
              width: 38%;
              position: absolute;
              top: ${verticalDistance}%; right: ${horizontalDistance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
          <div
            ref={bottomRightField}
            data-box-id="bottomRight"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'bottomRight' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'bottomRight' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
              height: 45%;
              width: 38%;
              position: absolute;
              bottom: ${verticalDistance}%; right:${horizontalDistance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
           <div
            ref={bottomLeftField}
            data-box-id="bottomLeft"
            css={css`
              border: 1px solid rgba(255,255,255,1);
              opacity: ${isActive && currentBoxId === 'bottomLeft' ? "1" : "0.7"};
              box-shadow: ${isActive && currentBoxId === 'bottomLeft' ? `0 0 10px -5px white;` : "none"}; 
              background: ${gt[1]};
              height: 45%;
              width: 38%;
              position: absolute;
              bottom: ${verticalDistance}%; left: ${horizontalDistance}%;
          `}
            onMouseEnter={handleMouseEnter}
            onTouchStart={handleTouchStart}
          ></div>
          
          {!isActive && countdownCopy && (
            <div css={css`
              text-align: center;
              color: white;
              font-weight: bold;
              font-size: 30px;
              animation: readySetGo 1s linear 0s infinite normal forwards;
              position: absolute;
              top: 0; left: 0; bottom: 0; right: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              pointer-events: none
            `}
            ><span>{countdownCopy}</span>
            </div>
          )}
    </div>

  );
}