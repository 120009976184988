/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientTypes } from '../context/SkidContext';
import { GameTitel } from '../core/GameTitle';
import { Stage } from "../core/Stage";
import { useGamePageRouter } from '../hooks/usePageRouter';
import { useSkid } from '../hooks/useSkid';

const coreBoxcss = `
  height: 40vw;
  width: 40vw;
  background: red;
  color: white;
  margin: 0 10px;
  border: 1px solid white;
  
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function getCss(type: string) {
  const gt = GradientTypes[type]
  return css`
  ${coreBoxcss}
  background: ${gt[0]};
  background: linear-gradient(220deg,${gt[0]} 0%, ${gt[1]} 100%);
`;
}

export const SelectTeam = () => {
  const { selectTeam, teams, selectedTeam, currentUser, isInitialLoading, isSavingTeamSelection, game } = useSkid()
  const { createRoute } = useGamePageRouter()
  const navigate = useNavigate()
  const onBoxClick = useCallback(async (teamId: string) => {
    await selectTeam(teamId)
    navigate(createRoute('warm-up'))
  }, [selectTeam, navigate, createRoute])

  useEffect(() => {
    if (!currentUser) {
      navigate(createRoute(''))
    }
    // ToDo: selected team is not present in Game
    if (selectedTeam) {
      navigate(createRoute('warm-up'))
    }
    
  }, [navigate, currentUser, selectedTeam, createRoute])

  return (
    <Stage isLoading={isInitialLoading || isSavingTeamSelection} gradiendDegree={0} top={<GameTitel name={game?.name}/>}>
          <div css={css`
              font-weight: bold;
              font-size: 40px;
              text-align: center;
              margin-bottom: 30px;
          `}>
              <span>Bitte wähle Dein Team</span>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
          `}
          >
            {teams.map((team) => <div key={team.id} css={getCss(team.gradientType)} onClick={() => onBoxClick(team.id)}><span>{team.name}</span></div>)}
          </div>
    </Stage>
  );
}