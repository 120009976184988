import Add from '@mui/icons-material/Add';
import { Button, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

export const AddGameButton = () => {
    const record = useRecordContext();

    return (
        <Button
            component={Link}
            to={`/events/${record.id}/games`}
            label="Add game"
            startIcon={<Add />}
        />
    );
};