
import { useMemo } from "react";
import { useSkid } from "./useSkid";
import { ResultTeam, ResultUser, useSkidResult } from "./useSkidResult";

export function useSkidResultTable(skidId?: string) {
    const { isInitialLoading, isLoading, teams } = useSkid();
    const { isInitialLoading: isResultInitialLoading, isLoading: isLoadingResults, result} = useSkidResult()

    const { data, tableData } = useMemo(() => {
        const data: {
            byTeam: Record<string, ResultTeam>,
            resultByTeam: Record<string, number>,
        } = {
            byTeam: {},
            resultByTeam: {},
        }

        if (!isResultInitialLoading && teams && skidId) {
            data.byTeam = result.teams

            for (const team of teams) {
                data.resultByTeam[team.id] = Boolean(result.teams[team.id]?.roundResult[skidId]) ? result.teams[team.id]?.roundResult[skidId].avg : 0
            }
        }

        
        let tableData: Record<string, ResultUser[]> = {};
        if (!isResultInitialLoading && result) {
            for (const team of teams) {
                tableData[team.id] = Object.values(result.users)
                    .filter((u) => u.teamId === team.id)
                    .sort((a, b) => {
                        if (a.totalScore === b.totalScore) return 0;

                        return a.totalScore < b.totalScore ? 1 : -1;
                    })
            }
        }

        return { data, tableData}

    }, [isResultInitialLoading, teams, result, skidId])

  return {
    isInitialLoading: isInitialLoading || isResultInitialLoading,
    isLoading: isLoading || isLoadingResults,
    data,
    tableData,
  }
}

