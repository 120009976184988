/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { DecisionsDescriptionOverlay } from './DecisionsDescriptionOverlay';

export const DecisionDescription: React.FunctionComponent<{
  cta: string
}> = ({ cta }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  return (
    <div css={css`
        text-align: center;
        color: white;
        margin-top: 40px;
        font-size: 16px
    `}>
      <div css={css`color: rgba(255,255,255, 0.9); margin-top: 10px; text-decoration: underline`} onClick={() => setOpen(true)}>
        <svg
          css={css`height: 16px;
          fill: white;
          display: inline-block;
          width: 18px;
          margin-right: 8px; margin-left: -22px`}
          xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M120-120v-300h60v198l558-558H540v-60h300v300h-60v-198L222-180h198v60H120Z"/></svg>
        {cta}
      </div>
      {isOpen && (
        <DecisionsDescriptionOverlay onClose={() => setOpen(false)} />
      )}
    </div>
  );
}