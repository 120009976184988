/** @jsxImportSource @emotion/react */

import { SkidProvider } from "./context/SkidContext";
import { Stage } from "./core/Stage";
import { useGame } from "./hooks/useGame";
import { useGamePageRouter } from "./hooks/usePageRouter";

export const GameLoader: React.FunctionComponent<{ children: React.ReactNode}> = ({ children }) => {
  const { eventId, gameId } = useGamePageRouter()
  const { game, teams, isLoading: isFetchingGameData, isInitialLoading, decisions } = useGame(eventId, gameId)
  
  if (isFetchingGameData || isInitialLoading) {
    <Stage isLoading={isFetchingGameData}></Stage>
  }

  return game ? (<SkidProvider eventId={eventId as string} gameId={gameId as string} game={game} teams={teams} decisions={decisions}>{children}</SkidProvider>) : (
    <Stage alignTop>
      
    </Stage>
  )
}