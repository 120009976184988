import { useMemo } from "react";
import { Decision } from "../client/skid-worker";
import { useGameState } from "./useGameState";
import { useSkid } from "./useSkid";
import { useSkidResult } from "./useSkidResult";

export type TeamData = {
  team_id: string,
  game_id: string,
  created_at: string,
  teams: {
    name: string
    created_at: string
    updated_at: string
  }
}

export type Team = {
  id: string
  name: string
  gradientType: string
}

export function useSkidDecisions() {
  const { isInitialLoading, isLoading, result } = useSkidResult();
  const { decisions, game } = useSkid();
  const { state } = useGameState(game)
  const decisionSelections = useMemo(() => state?.decisionSelections ?? {}, [state?.decisionSelections])

  const data = useMemo(() => {
    if (isInitialLoading) {
      return {}
    }

    const winnerDecisions: Record<string, Decision | null> = {}
    for (const skid of game.skids ?? []) {
      if (!result.skids[skid.id]) {
        winnerDecisions[skid.id] = null;
        continue;
      }

      const winnerTeamId = Object.entries(result.skids[skid.id]).reduce<any[]>((carry, result) => {
        if (carry.length === 0) {
          return result
        }

        return carry[1].avg > result[1].avg ? carry : result
      }, [])

      let winningDecision: Decision | undefined;
      if (!skid.can_teams_select) {
        winningDecision = { type: 'coin-toss', id: ''} as Decision
      } else if (decisionSelections[winnerTeamId[0]] && decisionSelections[winnerTeamId[0]][skid.id]) {
        winningDecision = decisions?.find(d => d.id === decisionSelections[winnerTeamId[0]][skid.id]);
      }


      winnerDecisions[skid.id] = winningDecision ?? null
    }

    return winnerDecisions;
  }, [isInitialLoading, game.skids, result.skids, decisionSelections, decisions])

  return {
    isInitialLoading,
    isLoading,
    data,
  }
}