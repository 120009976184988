import { Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

const UserEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const UserEdit = () => (
    <Edit>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput source="nickname" required/>
        </SimpleForm>
    </Edit>
);