/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';


export const Input: React.FunctionComponent<{ name: string; placeholder: string, onChange?: (e: string) => void }> = (args) => {
  const { onChange, ...rest } = args
  return (
    <>
      <input
          type='text'
          {...rest}
          onChange={(e) => {
            onChange && onChange(e.target.value)
          }}
          css={css`
              background: transparent;
              border-radius: 35px;
              border: 2px solid white;
              color: white;
              height: 70px;
              width: 300px;
              font-size: 24px;
              text-align: center;
              outline: 0;
              &:focus {
                box-shadow: 0px 0px 20px -10px yellow inset;
              }
              ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: white;
                opacity: 0.6;
              }
          `} />
    </>
  );
}