/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COOLDOWN_SECONDS, Cooldown } from '../decisions/Cooldown';
import { HotMinute } from '../decisions/HotMinute';
import { NoDecision } from '../decisions/NoDecision';
import { PreparationTime } from '../decisions/PreparationTime';
import { useGameState } from '../hooks/useGameState';
import { useGamePageRouter } from '../hooks/usePageRouter';
import { useSkid } from '../hooks/useSkid';

export const Decisions = () => {
  const navigate = useNavigate()
  const { hasCompletedWarmUp, game, isInitialLoading: isSkidInitialLoading } = useSkid();
  const [isHotMinuteOver, setIsHotMinuteover] = useState<null | Date>(null)
  const [isCooldownOver, setIsCooldownOver] = useState<null | Date>(null)

  useEffect(() => {
    if (!hasCompletedWarmUp) {
      navigate(createRoute('warm-up'))
    }
  }, [])

  const skids = useMemo(() => {
    return (game?.skids ?? []).sort((a, b) => a.order_number > b.order_number ? 1 : -1);
  }, [game?.skids])

  const { createRoute } = useGamePageRouter();
  const { isInitialLoading, state } = useGameState(game)

  const userState = useMemo(() => {
    if (isInitialLoading || isSkidInitialLoading || skids.length === 0) {
      return {
        stage: 0
      }
    }
    
    if (!state) {
      return {
        stage: 1,
        skid: skids[0]
      }
    }
    
    if (state.isComplete) {
      return {
        stage: 0,
      }
    }
    
    if (!state.skid) {
      return {
        stage: 0
      }
    }
    
    // if team selection -> prep time
    if (state.stage === 'team') {
      return {
        stage: 1,
        skid: state.skid
      }
    }

    const now = new Date();
    const isRoundOver = state.completedAt ? now.getTime() > state.completedAt.getTime() : false
    const shouldShowNextRound = state.completedAt ? ( now.getTime() - COOLDOWN_SECONDS) > state.completedAt.getTime() : false

    let skid = state.skid
    const currentIndex = skids.findIndex((d) => d.id === state?.skid?.id)
    const nextSkid = skids[currentIndex + 1]
    const hasNextSkid = Boolean(nextSkid);
    
    return {
      // if before complete -> hot minute
      // if after complete below cooldown -> cooldown
      // if after cooldown -> preparation
      // if no skid left -> cooldown without hidding
      stage: !isRoundOver ? 2 : (!shouldShowNextRound ? 3 : (hasNextSkid? 1 : 3)),
      isRoundOver,
      completedAt: state.completedAt,
      skid: shouldShowNextRound && hasNextSkid ? nextSkid : skid,
      isLastSkid: !hasNextSkid
    }
  },[isInitialLoading, isSkidInitialLoading, skids, state, isHotMinuteOver, isCooldownOver]) // leave isHotMinute and isCooldown

  const onCrunchTimeEnd = useCallback(() => setIsHotMinuteover((new Date())), [setIsHotMinuteover])
  const onCooldownEnd = useCallback(() => setIsCooldownOver((new Date())), [setIsCooldownOver])
  return (
    <>
        {userState.stage === 0 && <NoDecision />}
        {userState.stage ===  1 && (
          <PreparationTime isRoundOver={userState.isRoundOver} skid={userState.skid} />
        )}
        {userState.stage === 2 && (
          <HotMinute
            skid={userState.skid!}
            completedAt={userState.completedAt!}
            onHotMinuteEnd={onCrunchTimeEnd}
          />
        )}
        {userState.stage === 3 &&  (
          <Cooldown
            completedAt={userState.completedAt!}
            onCooldownEnd={onCooldownEnd}
            hideCountdown={userState.isLastSkid}
          />
        )}
    </>
  );
}