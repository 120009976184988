import OpenInNew from '@mui/icons-material/OpenInNew';
import { ArrayField, Button, Datagrid, EditButton, FunctionField, ListButton, Show, SimpleShowLayout, TextField, TopToolbar, useRecordContext } from "react-admin";
import { useParams } from 'react-router-dom';
import { Game } from "../../../hooks/useEvent";
import { EditGameButton } from '../games/EditGameButton';
import { ShowGameButton } from "../games/ShowGameButton";
import { AddGameButton } from './AddGameButton';

const EventShowActions = () => {
    const record = useRecordContext()
    const params = useParams()
    return (
        <TopToolbar>
            <ListButton />
            <EditButton />
            <Button color="primary" label="Event Admin" startIcon={<OpenInNew />} href={`/events/${record?.id ?? params.id}/admin`} />
        </TopToolbar>
    )
}
    
export const EventShow = () => (
    <Show actions={<EventShowActions />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <ArrayField source="games" >
              <Datagrid bulkActionButtons={false}>
                <TextField source="name" />
                <TextField source="type" label="Sport" />
                <FunctionField
                    source="skids"
                    render={(record: Game) => {
                        return record.skids?.length ?? 0
                    }}
                /> 
                <>
                    <ShowGameButton />
                    <EditGameButton />
                </>
              </Datagrid>
            </ArrayField>
            <AddGameButton />
        </SimpleShowLayout>
    </Show>
);