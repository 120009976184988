/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { GradientTypes } from '../context/SkidContext';
import { Button } from '../core/Button';
import { CountdownPlain } from '../core/CountdownPlain';
import { Stage } from '../core/Stage';
import { useCompletedAtTimer, useCountdownTimer } from '../hooks/useCountdownTimer';
import { Skid as SkidType } from '../hooks/useEvent';
import { useSaveSkid } from '../hooks/useSaveSkid';
import { useSkid } from '../hooks/useSkid';
import { useTeamDecisionSelection } from '../hooks/useTeamDecisionSelection';
import { Skid } from '../skid/Skid';
import { SkidFour } from '../skid/SkidFour';
import { SkidHeader } from '../skid/SkidHeader';
import { SkidScoreBoard } from '../skid/SkidScoreBoard';
import { SkidThree } from '../skid/SkidThree';

// ToDO: only show
export const CRUNCH_TIME_BEFORE_COMPLETE = 20 * 1000
export const WAIT_AFTER_COMPLETE = 5 * 1000

export const HotMinute = (
    { skid, completedAt, isTest, onHotMinuteEnd }: {
        skid: SkidType, completedAt: Date, isTest?: boolean,
        onHotMinuteEnd: () => void
    }
) => {
    const restMilliSeconds = useMemo(() => Math.round((completedAt.getTime() - (new Date()).getTime())), [completedAt]);
    const [skidCount, setSkidCount] = useState<number>(0)
    const [state, setState] = useState<{ canSkid: boolean, isInBufferTime: boolean}>({
        canSkid: restMilliSeconds > 0 && restMilliSeconds <= CRUNCH_TIME_BEFORE_COMPLETE,
        isInBufferTime: false
    })
    const { selectedTeam, isInitialLoading, decisionCopy } = useSkid();
    const { createSkid } = useSaveSkid()
    const teamSelectionForRound = useTeamDecisionSelection()
    
    // skid timer
    const { startCountdown, display, isActive: hasStartedSkidding, isTimerActive, timeLeft, timeLeftDisplay, hasFinished } = useCountdownTimer(5)
    
    const { timeLeft: timeUntilSkid, hasFinished: hasFinishedPreTime } = useCompletedAtTimer(completedAt, -1 * CRUNCH_TIME_BEFORE_COMPLETE, true, true)
    const { timeLeft: activeSkidTime, hasFinished: hasFinishedActiveTime, startCountdown: startActiveSkidTime } = useCompletedAtTimer(completedAt, 0, true, false)
    const { timeLeft: bufferTime, hasFinished: hasBufferTimeFinished, startCountdown: startBufferTime } = useCompletedAtTimer(completedAt, WAIT_AFTER_COMPLETE, true, false)

    useEffect(() => {
        if (hasFinishedPreTime && !hasFinishedActiveTime) {
            setState({
                canSkid: true,
                isInBufferTime: false
            })
            startActiveSkidTime()
            return;
        }

        if (hasFinishedActiveTime && !hasBufferTimeFinished) {
            setState({
                canSkid: false,
                isInBufferTime: true
            })
            startBufferTime()
            return
        }

        if (hasBufferTimeFinished) {
            onHotMinuteEnd()
        }
      }, [hasBufferTimeFinished, hasFinishedActiveTime, hasFinishedPreTime, onHotMinuteEnd, startActiveSkidTime, startBufferTime])
    
      
    useEffect(() => {
        if (hasFinished && timeLeft === 0 && !isTest) {
            createSkid({ score: skidCount, skidId: skid.id})
        }
    }, [skid.id, hasFinished, skidCount, timeLeft, isTest, createSkid])

    let bottom = undefined;
    if (state.canSkid && !hasStartedSkidding) {
        bottom = <Button copy='Skid it!' onClick={() => {
            startCountdown();
        }} gradiendDegree={90}/>
    }

    const gt = GradientTypes[selectedTeam?.gradientType ?? 'default']
    return (
        <Stage isLoading={isInitialLoading} gradiendDegree={0} gradientColorStart={gt[0]} gradientColorEnd={gt[1]} bottom={bottom} alignTop={true}>
            <div css={css`font-size:16px; font-weight: bold; text-align: center; color: white; `}>
                <span css={css`opacity: 0.6`}>Skiddet um </span>"<u>{decisionCopy[teamSelectionForRound?.type ?? '']?.name ?? teamSelectionForRound?.type}</u>"
            </div>
            <SkidHeader>
                {state.isInBufferTime && (
                    <div className="amboy" css={css`
                        text-align: center;
                        color: white;
                        font-weight: bold;
                        font-size: 30px;
                        letter-spacing: 1px;`
                    }>
                        Win or Loss?
                    </div>
                )}
                {!state.isInBufferTime && (<>
                    <div css={css`
                        text-align: center;
                        color: white;
                        font-weight: bold;
                        font-size: 26px;
                        ${state.canSkid ? 'animation: timer 1s linear 0s infinite normal forwards;' : ''}`
                    }>
                        {hasFinished ? "Warten auf andere" : state.canSkid ? "Crunch Time!" : "Mach dich bereit!"}
                    </div>
                    {!state.canSkid && (
                        <div css={css`font-size: 20px; padding-bottom: 5px; padding-top: 5px; `}>
                            <strong><CountdownPlain timeLeft={timeUntilSkid} hasFinished={hasFinishedPreTime} /></strong>
                            <span css={css`font-weight: normal; font-size: 17px;`}> bis zum skidden!</span>
                        </div> 
                    )}
                    {state.canSkid && (
                        <div css={css`font-size: 20px; padding-bottom: 5px; padding-top: 5px; `}>
                            <strong><CountdownPlain timeLeft={activeSkidTime} hasFinished={hasFinishedActiveTime} /></strong>
                            <span css={css`font-weight: normal; font-size: 17px;`}> um zu skidden!</span>
                        </div> 
                    )}
                </>
                )}
            </SkidHeader>
            <SkidScoreBoard left={skidCount} right={hasFinished ? '-' : timeLeftDisplay} leftLabel='Dein Score' rightLabel='Zeit' rightSuffix=' s' />
            
            {!hasFinished && !state.isInBufferTime && (
                <div css={css`margin-top: 40px`}>
                    {['swipe', 'two'].includes(skid.type) && (
                        <Skid isActive={isTimerActive} onSkid={() => {
                            if (isTimerActive) {
                                setSkidCount(skidCount + 1)
                                return;
                            }
                        }} skidZoneSize={'medium'} countdownCopy={display} />
                    )}
                    {['three'].includes(skid.type) && (
                        <SkidThree isActive={isTimerActive} onSkid={() => {
                            if (isTimerActive) {
                                setSkidCount(skidCount + 1)
                                return;
                            }
                        }} skidZoneSize={'xl'} countdownCopy={display} />
                    )}
                    {['four'].includes(skid?.type ?? '') && (
                        <SkidFour isActive={isTimerActive} onSkid={() => {
                            if (isTimerActive) {
                                setSkidCount(skidCount + 1)
                                return;
                            }
                        }} skidZoneSize={'2xl'} countdownCopy={display} />
                    )}
                </div>
            )}
            
                <div css={css`
                    text-align: center;
                    color: white;
                    padding-top: 40px;
                    font-weight: bold;
                    font-size: 20px;
                    height: 120px;
                `}>
                    {hasFinished || state.isInBufferTime ? (
                        <>
                            <div css={css`font-weight: normal; font-size: 20px;`}>
                                {state.isInBufferTime ? (<div>
                                    ... erfahre es in <CountdownPlain timeLeft={bufferTime} hasFinished={hasBufferTimeFinished} />
                                </div>
                                ) : ""}
                            </div>
                        </>
                    ): null}
                </div>
        </Stage>
    );      
}