/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { SelectedDecisionOverlay } from './SelectedDecisionsOverlay';

export const SelectedDecisions: React.FunctionComponent<{
  skidId?: string
  cta: string
}> = ({ skidId, cta }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  return (
    <div css={css`
        display: inline-block;
        margin-left: 10px;
    `}>
      <div css={css`color: rgba(255,255,255, 0.8); margin-top: 10px; text-decoration: underline; display: inline`} onClick={() => setOpen(true)}>
        {cta}
      </div>
      {isOpen && skidId && (
        <SelectedDecisionOverlay onClose={() => setOpen(false)} skidId={skidId} />
      )}
    </div>
  );
}