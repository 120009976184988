import { useMemo } from "react";
import { useQuery } from "react-query";
import { User, getSkidResults } from "../client/skid-worker";
import { useSkid } from "./useSkid";
import { useUser } from "./useUser";

export type ResultTeam = { results: Record<string, number[]>, name: string, id: string, userIds: string[], roundResult: Record<string, { avg: number, sum: number, count: number}>}
export type ResultUser = { name: string, id: string, teamId: string, roundScore: Record<string, number>, totalScore: number}
export type ResultSkid = Record<string, {
  avg: number
}>
export type Result = {
  teams: Record<string, ResultTeam>
  users: Record<string, ResultUser>
  skids: Record<string, ResultSkid>
}

export function useSkidResult() {
  const { eventId, game, teams } = useSkid()
  const { users, isLoadingUsers, isInitialLoadingUsers } = useUser()

  const { isLoading, isFetching, data } = useQuery(['result', game.id], async () => await getSkidResults(eventId, game.id) , {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: !!game.id
  })

  const result = useMemo(() => {
    const bag: Result = {
      teams: {},
      users: {},
      skids: {},
    }
    if (isLoading || !data || data.length === 0) {
      return bag;
    }

    for (const skid of data) {
      const { team_id: teamId, user_id: userId, score_int: score, skid_id: skidId } = skid;
      const teamName: string = teams.find((t) => t.id === teamId)?.name ?? 'Unknown';
      const userName = users.find((u: User) => u.id === userId)?.nickname ?? 'Unknown';
      
      if (!bag.teams[teamId]) {
        bag.teams[teamId] = {
          results: {},
          roundResult: {},
          name: teamName,
          id: teamId,
          userIds: [],
        }
      }

      if (!bag.users[userId]) {
        bag.teams[teamId].userIds.push(userId)
        bag.users[userId] = {
          roundScore: {},
          name: userName,
          id: userId,
          teamId: teamId,
          totalScore: 0,
        }
      }

      if (!bag.skids[skidId]) {
        bag.skids[skidId] = {}
      }
        
      if (!bag.skids[skidId][teamId]) {
        bag.skids[skidId][teamId] = { avg: 0}
      }

      if (!bag.teams[teamId].results[skidId]) {
        bag.teams[teamId].results[skidId] = []
      }

      if (!bag.teams[teamId].roundResult[skidId]) {
        bag.teams[teamId].roundResult[skidId] = { avg: 0, count: 0, sum: 0 }
      }

      if (!bag.teams[teamId].roundResult[skidId]) {
        bag.teams[teamId].roundResult[skidId] = { avg: 0, count: 0, sum: 0 }
      }

      bag.teams[teamId].results[skidId].push(score)
      bag.teams[teamId].roundResult[skidId].sum += score
      bag.teams[teamId].roundResult[skidId].count += 1
      bag.teams[teamId].roundResult[skidId].avg = Math.round(
        (bag.teams[teamId].roundResult[skidId].sum / bag.teams[teamId].roundResult[skidId].count) * 10
        ) / 10;
      bag.skids[skidId][teamId].avg = bag.teams[teamId].roundResult[skidId].avg

      bag.users[userId].roundScore[skidId] = score
      bag.users[userId].totalScore += score
    } 

    return bag
  }, [data, isLoading, teams, users])

  return {
    isInitialLoading: isLoading || isInitialLoadingUsers,
    isLoading: isFetching || isLoadingUsers,
    result
  }
}