/** @jsxImportSource @emotion/react */


export const FingerIcon = () => {
    return (
        <svg
        fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <g>
                <path d="M175.365,0c-4.564,0-8.264,3.699-8.264,8.264V41.32c0,4.565,3.7,8.264,8.264,8.264c4.564,0,8.264-3.699,8.264-8.264V8.264
                    C183.629,3.699,179.929,0,175.365,0z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M134.46,59.076L111.087,35.7c-3.227-3.226-8.46-3.226-11.686,0c-3.227,3.229-3.227,8.46,0,11.688l23.374,23.375
                    c1.613,1.614,3.729,2.421,5.843,2.421c2.115,0,4.23-0.807,5.843-2.42C137.688,67.535,137.688,62.304,134.46,59.076z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M251.331,35.701c-3.227-3.226-8.46-3.226-11.686,0l-23.374,23.375c-3.227,3.228-3.227,8.46,0,11.687
                    c1.613,1.613,3.728,2.42,5.843,2.42c2.114,0,4.23-0.807,5.843-2.42l23.374-23.375C254.558,44.161,254.558,38.929,251.331,35.701z"
                    />
            </g>
        </g>
        <g>
            <g>
                <path d="M373.701,215.414c-10.495,0-20.083,3.939-27.378,10.409c-5.854-15.714-21.006-26.937-38.734-26.937
                    c-10.495,0-20.083,3.939-27.378,10.409c-5.854-15.714-21.006-26.937-38.734-26.937c-9.295,0-17.881,3.086-24.792,8.287v-83.214
                    c0-22.784-18.536-41.32-41.32-41.32c-22.784,0-41.32,18.536-41.32,41.32V265.83c-18.837,3.839-33.056,20.533-33.056,40.488v49.584
                    c0,80.163,60.006,147.249,139.58,156.046c0.308,0.034,0.615,0.052,0.918,0.052c4.156,0,7.736-3.129,8.203-7.357
                    c0.501-4.536-2.769-8.62-7.305-9.121c-71.186-7.872-124.868-67.894-124.868-139.619v-49.584c0-10.773,6.91-19.959,16.528-23.371
                    v56.426c0,4.565,3.7,8.264,8.264,8.264s8.264-3.699,8.264-8.264V107.432c0-13.67,11.121-24.792,24.792-24.792
                    c13.671,0,24.792,11.122,24.792,24.792v165.831c0,4.565,3.7,8.264,8.264,8.264c4.564,0,8.264-3.699,8.264-8.264v-49.584
                    c0-13.67,11.121-24.792,24.792-24.792s24.792,11.122,24.792,24.792v49.584c0,4.565,3.7,8.264,8.264,8.264
                    c4.564,0,8.264-3.699,8.264-8.264v-33.056c0-13.67,11.121-24.792,24.792-24.792s24.792,11.122,24.792,24.792v33.056
                    c0,4.565,3.7,8.264,8.264,8.264c4.564,0,8.264-3.699,8.264-8.264v-16.528c0-13.67,11.121-24.792,24.792-24.792
                    s24.792,11.122,24.792,24.792v99.168c0,71.726-53.682,131.748-124.868,139.619c-4.536,0.501-7.807,4.585-7.305,9.121
                    c0.467,4.228,4.046,7.357,8.203,7.357c0.303,0,0.61-0.018,0.918-0.051c79.573-8.798,139.58-75.884,139.58-156.047v-99.168
                    C415.02,233.95,396.485,215.414,373.701,215.414z"/>
            </g>
        </g>
        </svg>
    )
}