
import { Datagrid, DateField, List, TextField } from 'react-admin';

export const DecisionList = () => {
    return (
        <List>
            <Datagrid rowClick="show" bulkActionButtons={false} >
                <TextField source="name" />
                <TextField source="sport" />
                <DateField source="updated_at" showTime={true} />
            </Datagrid>
        </List>
    );
}
