export const SPORT_TYPES = [
    'Beerpong',
    '3x3 Basketball',
    'Roundnet',
    'Headis',
    'Volleyball',
    'flunkyball', // backwarads compatible
];

export const SPORT_TYPES_INPUT = SPORT_TYPES.map((i) => ({ id: i, name: i}))

export const SKID_TYPE = [
    'swipe', 'two', // swipe
    'three', // swipe
    'four', // swipe
];

export const SKID_TYPES_INPUT = SKID_TYPE.map((i) => ({ id: i, name: i}))