/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

type SkidScoreBoardProps = {
  left?: number | null
  right?: number | string | null
  leftLabel: string
  rightLabel: string
  rightSuffix?: string
  noTopBorder?: boolean
  slim?: boolean
}

export const SkidScoreBoard: React.FunctionComponent<SkidScoreBoardProps> = ({ left, right, leftLabel, rightLabel, rightSuffix, slim, noTopBorder }) => {
    const fontSizeLabel = slim ? 12 : 14;
    const fontSizeNumber = slim? 30: 40;
    const letterSpacing = slim? -2: -3;
  return (
    <div
        css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 0.8);
            height: ${slim ? 75 : 100}px;
        `}
    >
        <div
            css={css`
                width: 50%;
                height: 100%;
                border: 2px solid white;
                border-left: none;
                border-right: none;
                ${noTopBorder ? 'border-top: none;' : ''}
                padding: 10px;
                font-size: ${fontSizeLabel}px;
                text-align: left
        `}
        >
            <span>{leftLabel}</span>
            <div
                css={css`
                    font-size: ${fontSizeNumber}px;
                    font-weight: bold;
                    color: white;
                    font-family: monospace;
                    letter-spacing: ${letterSpacing}px;
                `}
            >{left ?? '-'}</div>
        </div>
        <div
            css={css`
                width: 50%;
                height: 100%;
                border: 2px solid white;
                border-right: none;
                ${noTopBorder ? 'border-top: none;' : ''}
                padding: 10px;
                text-align: right;
                font-size: ${fontSizeLabel}px;
            `}
        >
            <span>{rightLabel}</span>
            <div
                css={css`
                    font-size: ${fontSizeNumber}px;
                    font-weight: bold;color: white;
                    font-family: monospace;
                    letter-spacing: ${letterSpacing}px;
                `}
            >{right ?? '-'}{rightSuffix ?? ' '}</div>
        </div>
    </div>
  );
}