import { useMemo } from "react";
import { useQuery } from "react-query";
import { getDecisionsByGameId, getTeamsByGameId } from "../client/skid-worker";
import { GradientTypes } from "../context/SkidContext";
import { useEvent } from "./useEvent";

export type TeamData = {
  team_id: string,
  game_id: string,
  created_at: string,
  teams: {
    name: string
    created_at: string
    updated_at: string
  }
}

export type Team = {
  id: string
  name: string
  gradientType: string
}

export function useGame(eventId?: string, gameId?: string) {
  const { event, isLoading, isInitialLoading } = useEvent(eventId)

  const { isLoading: isLoadingGameTeams, isFetching, data: teamData } = useQuery(['gameTeams', gameId], async () => await getTeamsByGameId(gameId as string), {
    enabled: Boolean(gameId)
  })
  
  const { isLoading: isLoadingGameDecisions, data: decisions } = useQuery(['gameDecisions', gameId], async () => await getDecisionsByGameId(gameId as string), {
    enabled: Boolean(gameId)
  })

  const game = useMemo(() => {
    if (event && event.games && event.games.length > 0) {
      return event.games.find((g) => g.id === gameId)
    }

    return undefined
  }, [event, gameId])

  const teams = useMemo(() => {
    if (teamData && teamData.length > 1) {
      return teamData.map((data: TeamData, index: number) => ({
        name: data.teams.name, id: data.team_id, gradientType: Object.keys(GradientTypes)[index]
      }))
    }

    return []
  }, [teamData])

  return {
    isInitialLoading: isInitialLoading || isLoadingGameTeams || isLoadingGameDecisions,
    isLoading: isLoading || isFetching,
    game,
    decisions,
    teams: teams as Team[],
  }
}