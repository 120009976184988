/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { Decision } from '../client/skid-worker';
import { Stage } from "../core/Stage";
import { useEvent } from '../hooks/useEvent';
import { useGame } from '../hooks/useGame';

export const EventAdmin = () => {
    const { eventId } = useParams();
    const [gameId, setGameId] = useState<string | undefined>()
    const [urlType, setUrlType] = useState<{type: string, id?: string} | undefined>()
    const { event, isInitialLoading } = useEvent(eventId)
    const { teams, game, decisions } = useGame(eventId, gameId)
    const thisUrl = new URL(window.location.href)

    const url = useMemo(() => {
        if (gameId && eventId && urlType) {
            switch (urlType.type) {
                case 'fan':
                return `/events/${eventId}/games/${gameId}`;

                case 'referee':
                return `/events/${eventId}/games/${gameId}/schiri`;

                case 'team':
                return `/events/${eventId}/games/${gameId}/teams/${urlType.id}`;

                case 'dashboard':
                return `/events/${eventId}/games/${gameId}/dashboard`;
            }
        }
        return undefined;
    }, [eventId, gameId, urlType])

    return (
        <Stage isLoading={isInitialLoading} noTop alignTop canScroll>
            {event && (<>
                <div css={css`margin-bottom: 20px;`}>Event: <strong>{event.name}</strong></div>
                <div
                    css={css`
                    margin-bottom: 20px;
                `}>
                    <select
                        onChange={(e) => e.currentTarget.value !== "" && setGameId(e.currentTarget.value)}
                        css={css`padding: 8px; font-size: 16px; background: rgba(255,255,255, 0.6); outline: 0; border-radius: 4px; max-width: 200px`}>
                        <option value="">bitte wähle ein Spiel</option>
                        {(event?.games?? []).map((e) => (
                            <option key={e.id} value={e.id}>{e.name}</option>
                        ))}
                    </select>
                </div>
            </>)}
            {gameId && (
                <div
                css={css`margin-bottom: 20px; display: flex; gap: 10px; border-bottom: 1px solid white; padding-bottom: 20px`}
                >
                    <span>Page:</span>
                    <button onClick={() => setUrlType({type: 'fan'})}>Fan</button>
                    <button onClick={() => setUrlType({type: 'referee'})}>Referee</button>
                    <button onClick={() => setUrlType({type: 'dashboard'})}>Dashboard</button>
                    {(teams ?? []).map((t) => <button key={t.id} onClick={() => setUrlType({type: 'team', id: t.id})}>Team: {t.name}</button>)}
                </div>
            )}
            {Boolean(url) && (
                <div css={css`color: white`}>
                    <div><a css={css`color: white`} href={url} target='_blank' rel="noreferrer">Gehe zu: {urlType?.type} {urlType?.id}</a></div>
                    <div css={css`height: auto; margin-top: 20px; max-width: 400px; width: 100%;`}>
                        <QRCode
                            size={256}
                            css={css`height: auto; maxWidth: 100%; width: 100%;`}
                            value={`${thisUrl.protocol}//${thisUrl.host}${url}`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <small>{thisUrl.protocol}//{thisUrl.host}{url}</small>
                </div>
            )}
            {game && (
                <div css={css`margin-top: 20px; padding-top: 20px; border-top: 1px solid white;`}>
                    <span>Rounds:</span>
                    <ol>{(game?.skids ?? []).sort((a,b) => a.order_number > b.order_number ? 1 : -1).map((s) => (<li key={s.id}>{s.name}</li>))}</ol>
                </div>
            )}
            {decisions && (
                <div css={css`margin-top: 20px; padding-top: 20px; border-top: 1px solid white;`}>
                    <span>FanCards:</span>
                    <ul>{(decisions ?? []).map((d: Decision) => (<li key={d.id}>{d.type}</li>))}</ul>
                </div>
            )}
        </Stage>
    );
}