import { useEffect, useState } from "react";
import UAParser from "ua-parser-js";

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

export const useWindowFocus = () => {
  // get the initial state
  const [focus, setFocus] = useState(hasFocus());
  const browser = (new UAParser(window.navigator.userAgent)).getBrowser()

  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    // assign the listener
    // update the status on the event
    if(browser.name  === 'Mobile Safari' && !navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/CriOS/i)){ // just for ipad / iphone / ipod (Safari mobile, not Android default browsers nor Chrome Mobile that is)
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
                setFocus(true)
            } else {
                setFocus(false);
            }
        });
        window.addEventListener('pagehide', onBlur);
        window.addEventListener('pageshow', onFocus);
    }else{ // the rest
        window.addEventListener('blur', onBlur);
        window.addEventListener('focus', onFocus);
    }

    // remove the listener
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("pagehide", onBlur);
      window.removeEventListener("pageshow", onFocus);
      window.removeEventListener("pageshow", onFocus)
    };
  }, []);

  // return the status
  return focus;
};